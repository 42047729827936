import React, { Component } from "react";
import { Button, Input, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as appointmentActions from "../../redux/actions/appointmentActions";
import * as employeeActions from "../../redux/actions/employeeActions";
import * as serviceActions from "../../redux/actions/serviceActions";
import { getServiceByName } from "../../redux/actions/serviceActions";
import * as modalActions from "../../redux/actions/modalActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import { showFailureAlert, showSuccessAlert } from "../root/AlertService";

export class UpdateAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      employeeOptions: [],
      serviceOptions: [],
      defaultCustomerOption: {},
      defaultEmployeeOption: {},
      defaultServiceOptions: [],
      selectedEmployeeID: 0,
      selectedServices: [],
      selectedDate: "",
      selectedTime: "",
      selectedTimeEnd: "",
    };
  }

  handleEmployeeChange = (selectedEmployee) => {
    const employeeID = selectedEmployee.value;
    this.setState({
      selectedEmployeeID: employeeID,
    });
  };

  handleDateChange = (e) => {
    const date = e.target.value;
    const selectedDateObject = new Date(date); // Convert the selected date to a Date object
    const year = selectedDateObject.getFullYear();
    const month = String(selectedDateObject.getMonth() + 1).padStart(2, "0");
    const day = String(selectedDateObject.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    this.setState({
      selectedDate: formattedDate,
    });
  };

  handleTimeChange = (e) => {
    const time = e.target.value;
    this.setState({
      selectedTime: time,
    });
  };

  handleTimeEndChange = (e) => {
    const timeEnd = e.target.value;
    this.setState({
      selectedTimeEnd: timeEnd,
    });
  };

  handleServicesChange = (selectedOptions) => {
    const selectedService = selectedOptions.map((option) => option.label);
    this.setState({
      selectedServices: selectedService,
    });
  };

  handleUpdateAppointment = async () => {
    const {
      selectedEmployeeID,
      selectedServices,
      selectedDate,
      selectedTime,
      selectedTimeEnd,
    } = this.state;

    const appointment = this.props.selectedAppointment;

    const [hours, minutes] = selectedTime.split(":");
    const [hoursEnd, minutesEnd] = selectedTimeEnd.split(":");
    let dateTime = new Date(selectedDate);
    let dateTimeEnd = new Date(selectedDate);

    dateTime.setHours(parseInt(hours) + 3);
    dateTime.setMinutes(minutes);
    dateTimeEnd.setHours(parseInt(hoursEnd) + 3);
    dateTimeEnd.setMinutes(minutesEnd);

    dateTime = dateTime.toISOString().slice(0, 19).replace("T", " ");
    dateTimeEnd = dateTimeEnd.toISOString().slice(0, 19).replace("T", " ");

    let selectedServicesStr = "";
    selectedServices.forEach((service) => {
      if (selectedServicesStr.length === 0) {
        selectedServicesStr = service;
      } else {
        selectedServicesStr = selectedServicesStr + ";" + service;
      }
    });

    /* console.log(
      "Selected \n" +
        selectedEmployeeID +
        "\n" +
        dateTime +
        "\n" +
        dateTimeEnd +
        "\n" +
        selectedServices +
        "\n"
    ); */

    try {
      const resultMessage = await this.props.actions.updateAppointment({
        appointmentID: appointment.id,
        employeeID: selectedEmployeeID,
        dateTime,
        dateTimeEnd,
        selectedServices: selectedServicesStr,
      });

      if (resultMessage.length > 0) {
        //fail
        showFailureAlert("Randevu güncellenemedi! "+resultMessage);
      } else {
        showSuccessAlert("Randevu güncellendi!");
        this.props.reloadAppointments();
      }
    } catch (error) {
      console.error("Beklenmeyen bir hata oluştu! :", error);
    }
    this.closeModal();
  };

  handleDeleteAppointment = async () => {
    const appointmentID = this.props.selectedAppointment.id;

    try {
      await this.props.actions.deleteAppointment({
        appointmentID,
      });

      this.props.refreshAppointments();
      showSuccessAlert("Randevu silindi!");
    } catch (error) {
      console.error("Beklenmeyen bir hata oluştu! :", error);
    }

    this.closeModal();
  };

  closeModal = () => {
    // Close the modal and reset the selectedAppointment
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: false });
    this.props.actions.updateSelectedAppointment({ selectedAppointment: null });
  };

  getDefaultOptions = async () => {
    const selectedAppointment = this.props.selectedAppointment;

    let selectedServicesStr = selectedAppointment.selectedServices; // Assume this contains the concatenated string
    let selectedServicesArray = selectedServicesStr.split(";");
    let defaultServiceOptions = [];

    const defaultCustomerOption = {
      value: selectedAppointment.customer.id,
      label:
        selectedAppointment.customer.name +
        " " +
        selectedAppointment.customer.surname,
    };

    const defaultEmployeeOption = {
      value: selectedAppointment.employee.id,
      label:
        selectedAppointment.employee.name +
        " " +
        selectedAppointment.employee.surname,
    };

    for (const serviceName of selectedServicesArray) {
      try {
        const service = await getServiceByName({
          serviceName,
        });
        const serviceOption = {
          value: service.id,
          label: service.name,
        };
        defaultServiceOptions.push(serviceOption);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }

    this.setState({
      defaultCustomerOption,
      defaultEmployeeOption,
      defaultServiceOptions,
    });
  };

  putDataToFields = async () => {
    const appointment = this.props.selectedAppointment;

    let selectedServicesStr = appointment.selectedServices; // Assume this contains the concatenated string
    let selectedServicesArray = selectedServicesStr.split(";");

    let dateTime = appointment.dateTime;
    let dateTimeEnd = appointment.dateTimeEnd;
    const [datePart, timePart] = dateTime.split(" ");
    const [dateEndPart, timeEndPart] = dateTimeEnd.split(" ");

    // Find the closest available 15-minute interval for the selected time
    const [hours, minutes] = timePart.split(":");
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    const formattedTime = `${String(hours).padStart(2, "0")}:${String(
      roundedMinutes
    ).padStart(2, "0")}`;

    // Find the closest available 15-minute interval for the selected time end
    const [hoursEnd, minutesEnd] = timeEndPart.split(":");
    const roundedMinutesEnd = Math.ceil(minutesEnd / 15) * 15;
    const formattedTimeEnd = `${String(hoursEnd).padStart(2, "0")}:${String(
      roundedMinutesEnd
    ).padStart(2, "0")}`;

    this.setState({
      selectedEmployeeID: appointment.employee.id,
      selectedServices: selectedServicesArray,
      selectedDate: datePart,
      selectedTime: formattedTime,
      selectedTimeEnd: formattedTimeEnd,
    });
  };

  putDataToOptions = async () => {
    const { employees, services } = this.props;

    let employeeOptions = [];
    let serviceOptions = [];

    employees.forEach((employee) => {
      let employeeOption = {
        value: employee.id,
        label: employee.name,
      };
      employeeOptions.push(employeeOption);
    });

    services.forEach((service) => {
      let serviceOption = {
        value: service.id,
        label: service.name,
      };
      serviceOptions.push(serviceOption);
    });

    this.setState({
      employeeOptions,
      serviceOptions,
    });
  };

  loadData = async () => {
    await this.props.actions.getAllEmployees({
      searchText: "",
      existingEmployees: [],
    });

    await this.props.actions.getAllServices({
      searchText: "",
      existingServices: [],
    });
  };

  initializeData = async () => {
    await this.loadData();
    await this.putDataToFields();
    await this.putDataToOptions();
    await this.getDefaultOptions();
    this.setState({
      isLoading: false,
    });
  };

  async componentDidMount() {
    await this.initializeData();
  }

  renderTimeOptions() {
    const timeOptions = [];
    for (let hours = 8; hours < 21; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeOptions.push(
          <option key={formattedTime} value={formattedTime}>
            {formattedTime}
          </option>
        );
      }
    }
    return timeOptions;
  }

  render() {
    const {
      isLoading,
      employeeOptions,
      serviceOptions,
      defaultCustomerOption,
      defaultEmployeeOption,
      defaultServiceOptions,
      selectedDate,
      selectedTime,
      selectedTimeEnd,
    } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <ModalHeader toggle={this.closeModal}>Randevu Bilgileri</ModalHeader>
        <ModalBody>
          <Select
            className="selectionBox"
            placeholder="Select Customer"
            defaultValue={defaultCustomerOption}
            isDisabled={true}
          ></Select>
          <Select
            className="selectionBox"
            placeholder="Select Employee"
            options={employeeOptions}
            defaultValue={defaultEmployeeOption}
            onChange={this.handleEmployeeChange}
          ></Select>
          <Input
            className="datePicker"
            name="date"
            placeholder="date placeholder"
            type="date"
            onChange={this.handleDateChange}
            defaultValue={selectedDate}
          />
          <Input
            className="timePicker"
            name="time"
            placeholder="time placeholder"
            type="select"
            onChange={this.handleTimeChange}
            defaultValue={selectedTime}
          >
            {this.renderTimeOptions()}
          </Input>
          <Input
            className="timePicker"
            name="timeEnd"
            placeholder="timeEnd placeholder"
            type="select"
            onChange={this.handleTimeEndChange}
            defaultValue={selectedTimeEnd}
          >
            {this.renderTimeOptions()}
          </Input>
          <Select
            className="multiSelectionBox"
            isMulti="true"
            placeholder="Select Services"
            options={serviceOptions}
            defaultValue={defaultServiceOptions}
            onChange={this.handleServicesChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: "#f58634", border: "none" }}
            onClick={this.handleUpdateAppointment}
          >
            Güncelle
          </Button>
          <Button
            color="danger"
            style={{ border: "none", margin: "10px" }}
            onClick={this.handleDeleteAppointment}
          >
            Sil
          </Button>
          <Button
            color="secondary"
            style={{ border: "none" }}
            onClick={this.closeModal}
          >
            İptal
          </Button>
        </ModalFooter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employees: state.employeeReducer,
    customers: state.customerReducer,
    services: state.serviceReducer,
    selectedAppointment: state.selectedAppointmentReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateAppointment: bindActionCreators(
        appointmentActions.updateAppointment,
        dispatch
      ),
      deleteAppointment: bindActionCreators(
        appointmentActions.deleteAppointment,
        dispatch
      ),
      updateSelectedAppointment: bindActionCreators(
        appointmentActions.updateSelectedAppointment,
        dispatch
      ),
      getAllEmployees: bindActionCreators(
        employeeActions.getAllEmployees,
        dispatch
      ),
      getAllServices: bindActionCreators(
        serviceActions.getAllServices,
        dispatch
      ),
      updateUpdateModal: bindActionCreators(
        modalActions.updateUpdateModal,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateAppointment);
