import "../root/App.css";

import React, { Component } from "react";
import { Input, Label } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalAction from "../../redux/actions/modalActions";
import * as employeeActions from "../../redux/actions/employeeActions";
import { showSuccessAlert } from "../root/AlertService";

export class CreateEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "",
      employeeSurname: "",
      employeeGender: false,
      employeeTelephone: "",
      employeeBirthday: "",
      error: "",
    };
  }

  handleChange = (e) => {
    const { name, value, type, checked, maxLength } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    if (type === "number") {
      //console.log("max : " + maxLength);
      if (value.length > maxLength) {
        let inputValue = value.slice(0, maxLength);
        this.setState({ [name]: inputValue });
      } else {
        if (value === "0") {
          this.setState({ [name]: "" });
        } else {
          this.setState({ [name]: value });
        }
      }
    } else {
      this.setState({ [name]: inputValue });
    }
  };

  handleChangeButton = async () => {
    const {
      employeeName,
      employeeSurname,
      employeeGender,
      employeeTelephone,
      employeeBirthday,
    } = this.state;

    try {
      if (
        !employeeName ||
        !employeeSurname ||
        !employeeTelephone ||
        !employeeBirthday
      ) {
        this.setState({ error: "Lütfen bütün boşlukları doldurun!" });
        return;
      } 
      
      await this.props.actions.createEmployee({
        employeeName,
        employeeSurname,
        employeeGender,
        employeeTelephone,
        employeeBirthday,
      });

      this.props.refreshEmployees();
      showSuccessAlert("Calışan eklendi!");
    } catch (error) {
      this.setState({ error: "Bir hata oluştu! Tekrar deneyiniz!" });
      return;
    }

    this.closeModal();
  };

  closeModal = () => {
    this.props.actions.updateModal({ isModalOpen: false });
  };

  render() {
    const { error } = this.state;
    return (
      <div style={{ width: "90%", margin: "10px" }}>
        <div className="row">
          <Input
            className="textField"
            name="employeeName"
            placeholder="İsim"
            onChange={this.handleChange}
            value={this.state.employeeName}
          />
          <Input
            className="textField"
            name="employeeSurname"
            placeholder="Soyisim"
            onChange={this.handleChange}
            value={this.state.employeeSurname}
          />
          <Label className="label">
            Erkek
            <Input
              className="checkBox"
              name="employeeGender"
              type="checkbox"
              onChange={this.handleChange}
              checked={this.state.employeeGender}
            />
          </Label>
        </div>
        <div className="row">
          <Input
            className="textField"
            name="employeeTelephone"
            placeholder="Telefon"
            type="number"
            maxLength="10"
            onChange={this.handleChange}
            value={this.state.employeeTelephone}
          />
          <Input
            className="textField"
            name="employeeBirthday"
            placeholder="Doğum Tarihi"
            type="date"
            onChange={this.handleChange}
            value={this.state.employeeBirthday}
          />
          <button className="button" onClick={this.handleChangeButton}>
            Çalışan ekle
          </button>
          <a
            style={{ marginLeft: "-20px", textDecoration: "underline" }}
            onClick={this.closeModal}
          >
            Iptal
          </a>
        </div>
        {error && (
          <div
            style={{ marginLeft: "-10px", color: "red", fontWeight: "bold" }}
          >
            {error}{" "}
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createEmployee: bindActionCreators(
        employeeActions.createEmployee,
        dispatch
      ),
      updateModal: bindActionCreators(modalAction.updateModal, dispatch),
    },
  };
}

export default connect(null, mapDispatchToProps)(CreateEmployee);
