import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
//import { Route, useNavigate } from "react-router-dom";

const mapStateToProps = (state) => ({
  isLoggedIn: state.authReducer,
});

const withAuthRedirect = (Component) => {
  const RedirectComponent = (props) => {
    //const navigate = useNavigate();
    if (!props.isLoggedIn) {
      return <Navigate to="/login" replace={true} />;
      //return navigate("/Login");
    }
    return <Component {...props} />;
  };

  return connect(mapStateToProps)(RedirectComponent);
};

export default withAuthRedirect;
