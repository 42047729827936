import React, { Component } from "react";
import { Button, Input, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as serviceActions from "../../redux/actions/serviceActions";
import * as modalActions from "../../redux/actions/modalActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showSuccessAlert } from "../root/AlertService";

export class UpdateService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedName: "",
    };
  }

  handleUpdateService = async () => {
    const { selectedName } = this.state;

    const service = this.props.selectedService[0];

    //console.log("Selected \n" + selectedName);

    try {
      await this.props.actions.updateService({
        serviceID: service.id,
        name: selectedName,
      });

      showSuccessAlert("Hizmet güncellendi!");
      this.props.refreshServices();
    } catch (error) {
      console.error("Beklenmeyen bir hata oluştu! :", error);
    }

    this.closeModal();
  };

  handleDeleteService = async () => {
    const serviceID = this.props.selectedService[0].id;
    await this.props.actions.deleteService({
      serviceID: serviceID,
    });

    showSuccessAlert("Hizmet silindi!");
    this.props.refreshServices();
    this.closeModal();
  };

  closeModal = () => {
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: false });
    this.props.actions.updateSelectedService({ selectedService: null });
  };

  putDataToFields = async () => {
    const service = await this.props.selectedService[0];

    //console.log(JSON.stringify(service));
    this.setState({
      selectedName: service.name,
    });
  };

  initializeData = async () => {
    await this.putDataToFields();
    //console.log("selected NAME : " + this.state.selectedName);
    this.setState({
      isLoading: false,
    });
  };

  async componentDidMount() {
    await this.initializeData();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const inputValue = value;
    this.setState({ [name]: inputValue });
  };

  render() {
    const { isLoading, selectedName } = this.state;

    //console.log("Selected Name : " + selectedName);

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <ModalHeader toggle={this.closeModal}>Hizmet Bilgileri</ModalHeader>
        <ModalBody>
          <Input
            className="textField"
            name="selectedName"
            placeholder="İsim"
            onChange={this.handleChange}
            value={selectedName}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: "#f58634", border: "none" }}
            onClick={this.handleUpdateService}
          >
            Güncelle
          </Button>
          <Button
            color="danger"
            style={{ border: "none", margin: "10px" }}
            onClick={this.handleDeleteService}
          >
            Sil
          </Button>
          <Button color="secondary" onClick={this.closeModal}>
            İptal
          </Button>
        </ModalFooter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedService: state.selectedServiceReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateService: bindActionCreators(serviceActions.updateService, dispatch),
      deleteService: bindActionCreators(serviceActions.deleteService, dispatch),
      updateSelectedService: bindActionCreators(
        serviceActions.updateSelectedService,
        dispatch
      ),
      updateUpdateModal: bindActionCreators(
        modalActions.updateUpdateModal,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateService);
