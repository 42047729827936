import React, { Component } from "react";
import Select from "react-select";
import "../root/App.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateAppointment from "./CreateAppointment";
import ReactModal from "react-modal";
import * as employeeActions from "../../redux/actions/employeeActions";
import * as appointmentActions from "../../redux/actions/appointmentActions";
import * as modalActions from "../../redux/actions/modalActions";

export class FilterCalender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeOptions: [],
      isLoading: true,
    };
  }

  openModal = () => {
    this.props.actions.updateModal({ isModalOpen: true });
  };

  closeModal = () => {
    this.props.actions.updateModal({ isModalOpen: false });
  };

  handleChange = async (selectedEmployee) => {
    const selectedEmployeeId = selectedEmployee.value;
    await this.props.actions.updateSelectedEmployeeId({ selectedEmployeeId });
    const employeeId = this.props.selectedEmployeeId;
    await this.props.actions.getAppointmentsForCalendar({
      startDate: this.props.firstDay,
      employeeId,
    });
  };

  setEmployeesToOptions = async () => {
    const employees = this.props.employees;
    let employeeOptions = [];

    await employees.forEach((employee) => {
      let employeeOption = {
        value: employee.id,
        label: employee.name,
      };
      employeeOptions.push(employeeOption);
    });

    this.setState({ employeeOptions });
  };

  loadAppointments = async () => {
      const limitCount = this.props.limitCount;
      const limitIndex = this.props.limitIndex;
      await this.props.actions.getEmployees({
        searchText: "",
        limitCount,
        limitIndex,
        existingEmployees: [],
      });
  }

  async componentDidMount() {
    await this.loadAppointments();
    await this.setEmployeesToOptions();
    this.setState({
      isLoading:false,
    })
  }
  render() {
    const { employeeOptions, isLoading } = this.state;
    const isModalOpen = this.props.isModalOpen;
    if(isLoading) {
      return (
        <div>Loading...</div>
      )
    }
    return (
      <div className="container">
        <div style={{ padding: "15px", display: "flex", alignItems: "baseline" }}>
          <Select
            className="selectionBox"
            placeholder="Çalışan seçiniz"
            isDisabled={false}
            isSearchable={true}
            options={employeeOptions}
            defaultValue={employeeOptions[0]}
            onChange={this.handleChange}
          ></Select>
          <button className="appointmentButton2" onClick={this.openModal}>
            Randevu ekle
          </button>
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={this.closeModal}
            contentLabel="Create Calender"
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
              content: {
                color: "orange",
                backgroundColor: "rgba(0,0,0,0.5)",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%,-50%)",
                border: "none",
                borderRadius: "10px",
              },
            }}
          >
            <CreateAppointment />
          </ReactModal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employees: state.employeeReducer,
    limitCount: state.limitCountReducer,
    limitIndex: state.limitIndexReducer,
    selectedEmployeeId: state.selectedEmployeeIdReducer,
    firstDay : state.firstDayOfWeekReducer,
    isModalOpen: state.modalReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getEmployees: bindActionCreators(employeeActions.getEmployees, dispatch),
      updateSelectedEmployeeId: bindActionCreators(
        employeeActions.updateSelectedEmployeeId,
        dispatch
      ),
      getAppointmentsForCalendar: bindActionCreators(
        appointmentActions.getAppointmentsForCalendar,
        dispatch
      ),
      updateModal: bindActionCreators(modalActions.updateModal, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterCalender);