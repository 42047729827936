import * as actionsTypes from "./actionTypes";
import * as dbInfo from "./dbInfo";
import { getCustomerById } from "./customerActions";
import { getEmployeeById } from "./employeeActions";


export function getSuccess(appointments) {
  return { type: actionsTypes.GET_APPOINTMENTS_SUCCESS, payload: appointments };
}

export function getFailure(message) {
  return { type: actionsTypes.GET_APPOINTMENTS_FAILURE, payload: message };
}

export function clearAppointmentsSuccess() {
  return {type: actionsTypes.CLEAR_APPOINTMENTS_SUCCESS}
}

export function updateExpiredAppointmentsSuccess(isExpired) {
  return {
    type: actionsTypes.UPDATE_EXPIRED_APPOINTMENTS,
    payload: isExpired,
  };
}

export function getCalendarSuccess(appointments) {
  return { type: actionsTypes.GET_APPOINTMENTS_CALENDAR_SUCCESS, payload: appointments };
}

export function updateFirstDayOfWeekSuccess(firstDay) {
  return {type: actionsTypes.UPDATE_FIRST_DAY_OF_WEEK_SUCCESS, payload: firstDay}
}

export function updateSelectedAppointmentSuccess(selectedAppointment) {
  return {type: actionsTypes.UPDATE_SELECTED_APPOINTMENT_SUCCESS, payload: selectedAppointment}
}


export function getAppointments({limitCount, limitIndex, searchText, getExpired, existingAppointments}) {
  return function (dispatch) {
    let url = "https://admin.beautyandrelaxyalova.com/barWebApp/getAppointmentsLimited.php";
    //console.log("Expired App : "+getExpired)
    let body = {
      limitIndex: limitIndex,
      limitCount: limitCount,
      searchText: searchText,
      getExpired: getExpired,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
          const appointments = result["appointments"];

          const appointmentPromises = appointments.map(async (appointment) =>{
            const customerPromise = getCustomerById(appointment.customerID);
            const employeePromise = getEmployeeById(appointment.employeeID);

            try {
              const customer = await customerPromise;
              const employee = await employeePromise;

              appointment.employee = employee;
              appointment.customer = customer;
              
              return appointment;
            } catch (error) {
              // Handle error fetching customer or employee
              console.error(error);
              throw error;
            }
          });

          Promise.all(appointmentPromises)
            .then((resolvedAppointments) => {
              const newAppointments = existingAppointments.concat(resolvedAppointments);
              //console.log(JSON.stringify(newAppointments))
              if(newAppointments.length>0) {
                return dispatch(getSuccess(newAppointments));
              }
              return "Randevu bulunamadı!";
            })
            .catch((error) => {
              return dispatch(getFailure(error.message));
            });
        } else {
          return result["message"];
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function clearAppointments() {
  return function (dispatch) {
    return dispatch(clearAppointmentsSuccess());
  }
}

export function updateExpiredAppointments ({oldVal}) {
  return function (dispatch) {
    return dispatch(updateExpiredAppointmentsSuccess(oldVal))
  }
}

export function updateFirstDayOfWeek({firstDay}) {
  return function(dispatch) {
    return dispatch(updateFirstDayOfWeekSuccess(firstDay))
  }
}

export function getAppointmentsForCalendar ({startDate, employeeId}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/getAppointmentsForCalendar.php";

    let body = {
      startDate,
      employeeId,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
          let tableMap = {};
          const appointments = result["appointments"];

          for (let i = 0; i < appointments.length; i++) {
            const dateTime = new Date(appointments[i]["dateTime"]);
            const dateTimeEnd = new Date(appointments[i]["dateTimeEnd"]);
            // const hour = dateTime.getHours();
            // let minutes = dateTime.getMinutes();
            // let hourEnd = dateTimeEnd.getHours();
            // let minutesEnd = dateTimeEnd.getMinutes();
            let dayIndex = dateTime.getDay();

            if (dayIndex === 0) {
              dayIndex = 7;
            }
            while (dateTime < dateTimeEnd) {
              //console.log("CurrentDateTime : "+dateTime)
              if (!tableMap[dayIndex]) {
                tableMap[dayIndex] = {};
              }

              const timeKey = `${dateTime.getHours()}.${dateTime.getMinutes()}`;
              tableMap[dayIndex][timeKey] = appointments[i];
              dateTime.setTime(dateTime.getTime() + 15 * 60 * 1000);
            }

            // outerLoop: for (let j = hour; j < hourEnd + 1; j++) {
            //   for (let k = minutes; k < 60; k += 15) {
            //     if (j !== hour && minutes !== 0) {
            //       minutes = -15;
            //       continue;
            //     } else {
            //       if (j === hourEnd && k >= minutesEnd) {
            //         break outerLoop;
            //       }
            //       if (!tableMap[dayIndex]) {
            //         tableMap[dayIndex] = {};
            //       }

            //       const timeKey = `${j}.${k}`;
            //       tableMap[dayIndex][timeKey] = appointments[i];
            //     }
            //   }
            // }
          }
          //console.log("Result Of Table Map : " + JSON.stringify(tableMap));
          return dispatch(getCalendarSuccess(tableMap));
        }else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function createAppointment({customerID, employeeID, dateTime, dateTimeEnd, selectedServices}) {
  return function(dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/createAppointment.php";

    let body = {
      customerID,
      employeeID,
      dateTime,
      dateTimeEnd,
      selectedServices,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(JSON.stringify(result))
        if(result["success"]===true) {
          return result["result"];
        } else {
          return result["message"];
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }
}

export function updateSelectedAppointment({selectedAppointment}) {
  return function(dispatch) {
    return dispatch(updateSelectedAppointmentSuccess(selectedAppointment));
  }
}

export function updateAppointment({
  appointmentID,
  employeeID,
  dateTime,
  dateTimeEnd,
  selectedServices,
}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/updateAppointment.php";

    let body = {
      appointmentID,
      employeeID,
      dateTime,
      dateTimeEnd,
      selectedServices,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
          return "";
        } else {
          return result["message"];
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function deleteAppointment({
  appointmentID,
}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/deleteAppointment.php";

    let body = {
      appointmentID,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function getAppointmentById({ id }) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/getAppointmentById.php";

    let body = {
      id,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(JSON.stringify(result))
        if (result["success"] === true) {
          return result;
        } else {
          return result;
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}
//response.json()
//dispatch(getProductsSuccess(result))
