import "../root/App.css";
import React, { Component } from 'react'
import { Input } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as modalAction from "../../redux/actions/modalActions";
import * as customerActions from "../../redux/actions/customerActions"
import * as loanActions from "../../redux/actions/loanActions"
import Select from "react-select";
import { showSuccessAlert } from "../root/AlertService";

export class CreateLoans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerOptions: [],
      customerID: 0,
      description: "",
      amount: 0,
      isLoading: true,
      customerSearchText: "",
    };
  }

  handleCustomerChange = (selectedCustomer) => {
    const customerID = selectedCustomer.value;
    //console.log("SelectedCustomer ID : " + customerID);
    this.setState({
      customerID: customerID,
    });
  };

  putCustomersToOptions = async () => {
    const customers = this.props.customers;
    let customerOptions = [];
    customers.forEach((customer) => {
      let customerOption = {
        value: customer.id,
        label: customer.name,
      };
      customerOptions.push(customerOption);
    });
    this.setState({
      customerOptions,
      isLoading: false,
    });
  };

  loadCustomers = async () => {
    const customerSearchText = this.state.customerSearchText;
    await this.props.actions.getCustomers({
      searchText: customerSearchText,
      limitCount: 25,
      limitIndex: 1,
      existingCustomers: [],
    });

    this.putCustomersToOptions();
  };

  async componentDidMount() {
    await this.loadCustomers();
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    
    this.setState({ [name]: value });
  };

  handleButtonClick = async () => {
    const {customerID, description, amount} = this.state;

     // Überprüfen, ob eines der Felder leer ist
     if (!customerID ||!description || !amount) {
      this.setState({ error: "Lütfen bütün boşlukları doldurun." });
      return;
    }

    await this.props.actions.createLoan({customerID,description,amount})
    showSuccessAlert("Ödeme eklendi!");
    this.props.refreshLoans();
    this.closeModal();
  }

  handleCustomerInputChange = (customerSearchText) => {
    // Case-insensitive search
    this.setState({
      customerSearchText,
    });
    this.loadCustomers();
  };
  
  closeModal = () => {
    this.props.actions.updateModal({ isModalOpen: false });
  };

  render() {
    const { isLoading, customerOptions, error } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }
    return (
      <div style={{margin: "10px", justifyContent: "center"}}>
        <div className="row">
          <Select
            className="selectionBox"
            placeholder="Select Customer"
            options={customerOptions}
            onChange={this.handleCustomerChange}
            onInputChange={this.handleCustomerInputChange}
            filterOption={null}
          ></Select>
          <Input
            className="textField"
            style={{ width: "90%", marginTop: "-8px"}}
            name="description"
            placeholder="Aciklama"
            onChange={this.handleChange}
            value={this.state.description}
          />
          <Input
            className="textField"
            style={{ width: "90%" }}
            name="amount"
            type="number"
            placeholder="Tutar"
            onChange={this.handleChange}
            value={this.state.amount}
          />
          <button className="button" style={{ width: "90%"}} onClick={this.handleButtonClick}>Onayla</button>
          <a style={{marginLeft: "20px", textDecoration: "underline"}} onClick={this.closeModal}>Iptal</a>
        </div>
        {error && <div style={{marginLeft: "10px", color: "red", fontWeight: "bold"}}>{error}</div>}{" "}
        {/* Anzeigen der Fehlermeldung, wenn vorhanden */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    customers: state.customerReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createLoan: bindActionCreators(
        loanActions.createLoan,
        dispatch
      ),
      updateModal: bindActionCreators(modalAction.updateModal, dispatch),
      getCustomers: bindActionCreators(customerActions.getCustomers, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLoans);