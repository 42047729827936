import * as dbInfo from "./dbInfo";
import { showFailureAlert, showSuccessAlert } from "../../components/root/AlertService";

export function createPayment({ loanID, description, amount }) {
  return function (dispatch) {
    let url = "https://admin.beautyandrelaxyalova.com/barWebApp/createPayment.php";
    let body = {
      loanID,
      description,
      amount,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
        } else {
          return result["message"];
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function deletePayment({ paymentID }) {
  return function (dispatch) {
    let url = "https://admin.beautyandrelaxyalova.com/barWebApp/deletePayment.php";

    let body = {
      paymentID,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
          showSuccessAlert("Ödeme Silindi!");
        } else {
          showFailureAlert("Hata! Ödeme silinemedi. " + result["message"]);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

