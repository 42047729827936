import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function limitIndexReducer(state = initialState.limitIndex, action) {
  switch (action.type) {
    case actionTypes.UPDATE_LIMIT_INDEX:
      return state+1;
    case actionTypes.RESET_LIMIT_INDEX:
      return 1;
    default:
      return state;
  }
}
