import React, { Component } from 'react'
import EmployeesTable from './EmployeesTable'
import FilterEmployee from "./FilterEmployee";
import withAuthRedirect from '../../hoc/withAuthRedirect';

export class Employees extends Component {
  render() {
    return (
      <div>
        <FilterEmployee></FilterEmployee>
        <EmployeesTable></EmployeesTable>
      </div>
    )
  }
}

export default withAuthRedirect(Employees);
