import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function employeeReducer(
  state = initialState.employees,
  action
) {
  switch (action.type) {
    case actionTypes.GET_EMPLOYEES_SUCCESS:
      return action.payload;
    case actionTypes.CLEAR_EMPLOYEES_SUCCESS:
      return [];
    default:
      return state;
  }
}
