import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function firstDayOfWeekReducer(
  state = initialState.firstDayOfWeek,
  action
) {
  switch (action.type) {
    case actionTypes.UPDATE_FIRST_DAY_OF_WEEK_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
