const showSuccessAlert = (message) => {
  // Zeige die Erfolgsmeldung an
  const alertDiv = document.createElement("div");
  alertDiv.className = "success-alert";
  alertDiv.textContent = message;
  alertDiv.style.display = "block";
  alertDiv.style.position = "fixed";
  alertDiv.style.top = "10px";
  alertDiv.style.padding = "10px";
  alertDiv.style.backgroundColor = "#4CAF50"; // Green
  alertDiv.style.color = "white";
  alertDiv.style.textAlign = "center";
  alertDiv.style.width = "100%";
  alertDiv.style.zIndex = "9999";

  document.body.appendChild(alertDiv);

  // Blende die Erfolgsmeldung nach 3 Sekunden automatisch aus
  setTimeout(() => {
    alertDiv.style.display = "none";
  }, 3000); // 3000 Millisekunden = 3 Sekunden
};

const showFailureAlert = (message) => {
  // Zeige die Erfolgsmeldung an
  const alertDiv = document.createElement("div");
  alertDiv.className = "danger-alert";
  alertDiv.textContent = message;
  alertDiv.style.display = "block";
  alertDiv.style.position = "fixed";
  alertDiv.style.top = "10px";
  alertDiv.style.padding = "10px";
  alertDiv.style.backgroundColor = "#ff0f0f"; // RED
  alertDiv.style.color = "white";
  alertDiv.style.textAlign = "center";
  alertDiv.style.width = "100%";
  alertDiv.style.zIndex = "9999";

  document.body.appendChild(alertDiv);

  // Blende die Erfolgsmeldung nach 3 Sekunden automatisch aus
  setTimeout(() => {
    alertDiv.style.display = "none";
  }, 5000); // 3000 Millisekunden = 3 Sekunden
};


export {showFailureAlert, showSuccessAlert}