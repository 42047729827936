import React, { Component } from "react";
import "../root/App.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as serviceActions from "../../redux/actions/serviceActions";
import { showSuccessAlert } from "../root/AlertService";

export class CreateService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceName: "",
      error: "",
    };

    this.serviceInputRef = React.createRef(); // Ref erstellen
  }

  handleChange = (e) => {
    const serviceName = e.target.value;
    this.setState({ serviceName, error: "" });
  };

  handleKeyDown = (e) => {
    const isValidInput = /^[a-zA-Z\s]*$/.test(e.key);
    if (!isValidInput) {
      e.preventDefault();
    }
  };

  handleChangeButton = async (e) => {
    try {
      if (this.state.serviceName.trim() === "") {
        throw new Error("Lütfen hizmet giriniz!");
      }

      await this.props.actions.createServices({
        serviceName: this.state.serviceName,
      });

      showSuccessAlert("Hizmet eklendi!");
      this.setState({
        serviceName: "",
      })
      this.serviceInputRef.current.value = "";
    } catch (error) {
      this.setState({ error: error.message });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <div className="container">
        <div
          style={{
            // display: "flex",
            alignItems: "baseline",
            justifyContent: "space-between",
            padding: "20px",
          }}
        >
          <input
            className="serviceTextField"
            name="name"
            placeholder="Hizmet ekleyin"
            style={{
              // paddingLeft: "0",
              borderRadius: "6px",
              // marginRight: "10px",
              width: "100%",
            }}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown} // Event-Handler für Buchstabenbeschränkung
            ref={this.serviceInputRef} // Ref zuweisen
          />
          {error && (
            <div
              style={{ marginLeft: "5px", color: "red", fontWeight: "bold" }}
            >
              {error}
            </div>
          )}{" "}
          <button className="serviceButton" onClick={this.handleChangeButton}>
            Hizmet ekle
          </button>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createServices: bindActionCreators(
        serviceActions.createServices,
        dispatch
      ),
    },
  };
}

export default connect(null, mapDispatchToProps)(CreateService);
