const API_KEY = "5a9245a03643ffd1247648973d1473a655be4d27";
const TITLE = "BEAUTYRELAX";
const VALIDITY = "2880";
let SALUTE_MESSAGE = "Merhaba,";
let INSTANT_MESSAGE = "Randevunuz oluşturulmuştur.";
let BYE_MESSAGE = "Sağlıklı Günler";

const sendSMS = (appointmentModel) => {
  const customer_name = appointmentModel.name;
  const date = new Date(appointmentModel.dateTime).toLocaleDateString("tr-TR");
  const time = new Date(appointmentModel.dateTime).toLocaleTimeString("tr-TR");
  let salutation = "hanım";
  if (appointmentModel.isWomen) {
    salutation = "bey";
  }
  SALUTE_MESSAGE = `Merhaba ${customer_name} ${salutation},`;
  INSTANT_MESSAGE = `${date} tarihinde saat ${time}’e randevunuz alınmıştır.`;
  BYE_MESSAGE = "Bilginize sunar ve sağlıklı günler dileriz.";

  const customerTelephone = appointmentModel.telephone;

  const url = "https://sms2.sanalsantral.com.tr/api/smspost/v1";
  //console.log(customer_name+"\n"+date+"\n"+time+"\n"+salutation+"\n"+customerTelephone)
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "text/xml; charset=UTF- 8",
      "Content-Encoding": "UTF-8",
    },
    body: `<?xml version="1.0" encoding="UTF-8"?>
            <sms>
              <apikey>${API_KEY}</apikey>
              <header>${TITLE}</header>
              <validity>${VALIDITY}</validity>
              <type>1</type>
              <ticari>0</ticari>
              <message>
                <gsm>
                  <no>${customerTelephone}</no>
                </gsm>
                <msg>
                  ${SALUTE_MESSAGE} ${"\n"} ${INSTANT_MESSAGE} ${"\n"} ${BYE_MESSAGE}
                </msg>
              </message>
            </sms>`,
    mode: "no-cors",
  })
    .then((response) => response.text())
    .then((data) => {
      //console.log(data);
    })
    .catch((error) => {
      console.error(error);
    });
};

const planSMSReminder = (appointmentModel) => {
  const customer_name = appointmentModel.name;
  const dateTime = new Date(appointmentModel.dateTime);
  dateTime.setDate(dateTime.getDate() - 1);
  const formattedDateTime = dateTime
    .toISOString()
    .slice(0, 19)
    .replace("T", " ");

  const time = new Date(appointmentModel.dateTime).toLocaleTimeString("tr-TR");
  let salutation = "hanım";
  if (!appointmentModel.isWomen) {
    salutation = "bey";
  }
  SALUTE_MESSAGE = `Merhaba ${customer_name} ${salutation},`;
  INSTANT_MESSAGE = `Yarın randevu saatiniz ${time} olarak kayıt edilmiştir. Gelemeyecek iseniz lütfen bildiriniz.`;
  BYE_MESSAGE = "Sağlıklı günler dileriz!";

  const customerTelephone = appointmentModel.telephone;

  const url = "https://sms2.sanalsantral.com.tr/api/smspostdate/v1";
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "text/xml; charset=UTF- 8",
      "Content-Encoding": "UTF-8",
    },
    body: `<?xml version="1.0" encoding="UTF-8"?>
            <sms>
              <apikey>${API_KEY}</apikey>
              <header>${TITLE}</header>
              <validity>${VALIDITY}</validity>
              <type>1</type>
              <ticari>0</ticari>
              <zamanla>${formattedDateTime}</zamanla>
              <message>
                <gsm>
                  <no>${customerTelephone}</no>
                </gsm>
                <msg>
                  ${SALUTE_MESSAGE} \n ${INSTANT_MESSAGE} \n ${BYE_MESSAGE}
                </msg>
              </message>
            </sms>`,
    mode: "no-cors",
  })
    .then((response) => response.text())
    .then((data) => {
      //console.log(data);
    })
    .catch((error) => {
      console.error(error);
    });
};

export { sendSMS, planSMSReminder };
