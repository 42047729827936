import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import "../root/App.css"
import { connect } from "react-redux";

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Appointments"
    };
  }

  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { activeTab } = this.state;
    const{isLoggedIn} = this.props;
    if (!isLoggedIn){
      return(
        <div></div>
      )

    }
    return (
      <Nav fill tabs>
        <NavItem>
          <NavLink active={activeTab === "Appointments"} tag={Link} to="/appointments" onClick={() => this.setActiveTab("Appointments")}>
            Appointments
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === "Calendar"} tag={Link} to="/calendar" onClick={() => this.setActiveTab("Calendar")}>
            Calendar
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === "Employees"} tag={Link} to="/employees" onClick={() => this.setActiveTab("Employees")}>
            Employees
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === "Customers"} tag={Link} to="/customers" onClick={() => this.setActiveTab("Customers")}>
            Customers
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === "Services"} tag={Link} to="/services" onClick={() => this.setActiveTab("Services")}>
            Services
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink active={activeTab === "Loans"} tag={Link} to="/loans" onClick={() => this.setActiveTab("Loans")}>
            Loans
          </NavLink>
        </NavItem>
      </Nav>
    );
  }

}
function mapStateToProps(state) {
  return {
    isLoggedIn: state.authReducer,
  };
}

export default connect(mapStateToProps, null)(NavBar);
