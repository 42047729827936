import "../root/App.css";

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Input, FormGroup, Label } from "reactstrap";

import * as appointmentActions from "../../redux/actions/appointmentActions";
import * as filterActions from "../../redux/actions/filterActions"
import * as modalAction from "../../redux/actions/modalActions";

import CreateAppointment from "./CreateAppointment";
import ReactModal from "react-modal";

export class FilterAppointments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    }
  }

  handleSearch = async (event) => {
    const searchText = event.target.value;

    await this.props.actions.updateSearchText({searchText});
    await this.props.actions.resetLimitIndex();

    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchTextVal = this.props.searchText;
    const getExpired = this.props.isExpiredAppointments;

    await this.props.actions.getAppointments({
      limitIndex,
      limitCount,
      searchText : searchTextVal,
      getExpired,
      existingAppointments: [],
    });
  }

  handleChange = async () => {
    this.props.actions.updateMoreBtn({isVisible: true})
    const isExpiredAppointments = this.props.isExpiredAppointments;
    const newIsExpired = !isExpiredAppointments;
    await this.props.actions.updateExpiredAppointments({oldVal:newIsExpired});
    await this.reloadAppointments();
  }

  reloadAppointments = async () => {
    await this.props.actions.resetLimitIndex();
    await this.props.actions.resetSearchText();
    await this.props.actions.resetMoreBtn();
    
    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchText = this.props.searchText;
    const getExpired = this.props.isExpiredAppointments;

    const resultMessage = await this.props.actions.getAppointments({
      limitIndex,
      limitCount,
      searchText,
      getExpired,
      existingAppointments: [],
    });

    if (
      Object.prototype.toString.call(resultMessage) === "[object String]" &&
      (resultMessage === "empty" || resultMessage === "Randevu bulunamadı!")
    ) {
      this.props.actions.updateErrorMessage({
        errorMessage: "Randevu bulunamadı!",
      });
    } else {
      this.props.actions.updateErrorMessage({ errorMessage: "" });
    }
  }

  openModal = () => {
    this.props.actions.updateModal({ isModalOpen: true });
  };

  closeModal = () => {
    this.props.actions.updateModal({ isModalOpen: false });
  };

  render() {
    const { isModalOpen } = this.props;

    return (
      <div className="container">
        <div className="row3 d-flex flex-column">
          <input
            className="searchField"
            onChange={this.handleSearch}
            name="search"
            placeholder="Randevu ara..."
            type="search"
          />
          <div className="d-flex justify-content-between align-items-center m-2">
            <FormGroup switch className="switchExpired">
              <Input
                onChange={this.handleChange}
                checked={this.props.isExpiredAppointments}
                type="switch"
                role="switch"
              />
              <Label check>Geçmiş randevuları göster</Label>
            </FormGroup>
            <button
              className="appointmentButton2 text-white btn btn-warning"
              onClick={this.openModal}
            >
              Randevu ekle
            </button>
          </div>
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
              content: {
                color: "orange",
                backgroundColor: "rgba(0,0,0,0.5)",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%,-50%)",
                border: "none",
                borderRadius: "10px",
              },
            }}
          >
            <CreateAppointment reloadAppointments={this.reloadAppointments} />
          </ReactModal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appointments: state.appointmentReducer,
    isExpiredAppointments: state.isExpiredReducer,
    limitIndex: state.limitIndexReducer,
    limitCount: state.limitCountReducer,
    searchText: state.searchTextReducer,
    isModalOpen: state.modalReducer,
    errorMessage: state.errorMessageReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateExpiredAppointments: bindActionCreators(
        appointmentActions.updateExpiredAppointments,
        dispatch
      ),
      getAppointments: bindActionCreators(
        appointmentActions.getAppointments,
        dispatch
      ),
      resetLimitIndex: bindActionCreators(
        filterActions.resetLimitIndex,
        dispatch
      ),
      updateSearchText: bindActionCreators(
        filterActions.updateSearchText,
        dispatch
      ),
      updateModal: bindActionCreators(modalAction.updateModal, dispatch),
      updateMoreBtn: bindActionCreators(filterActions.updateMoreBtn, dispatch),
      resetSearchText: bindActionCreators(
        filterActions.resetSearchText,
        dispatch
      ),
      resetMoreBtn: bindActionCreators(filterActions.resetMoreBtn, dispatch),
      updateErrorMessage: bindActionCreators(
        filterActions.updateErroMessage,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterAppointments);
