import "../root/App.css";
import React, { Component } from "react";
import { Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";
import * as appointmentActions from "../../redux/actions/appointmentActions";
import * as employeeActions from "../../redux/actions/employeeActions";
import * as customerActions from "../../redux/actions/customerActions";
import * as serviceActions from "../../redux/actions/serviceActions";
import * as modalActions from "../../redux/actions/modalActions";
import { sendSMS, planSMSReminder } from "./SmsService";
import { showFailureAlert, showSuccessAlert } from "../root/AlertService";

export class CreateAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeOptions: [],
      customerOptions: [],
      serviceOptions: [],
      selectedEmployeeID: 0,
      selectedCustomerID: 0,
      selectedServices: [],
      selectedDate: "",
      selectedTime: "08:00",
      selectedTimeEnd: "",
      isLoading: true,
      customerSearchText: "",
      employeeSearchText: "",
      serviceSearchText: "",
    };
  }

  handleCustomerChange = (selectedCustomer) => {
    const customerID = selectedCustomer.value;
    //console.log("SelectedCustomer ID : " + customerID);
    this.setState({
      selectedCustomerID: customerID,
    });
  };

  handleEmployeeChange = (selectedEmployee) => {
    const employeeID = selectedEmployee.value;
    this.setState({
      selectedEmployeeID: employeeID,
    });
  };

  handleDateChange = (e) => {
    const date = e.target.value;
    //console.log("Selected Date : " + date);
    this.setState({
      selectedDate: new Date(date),
    });
  };

  handleTimeChange = (e) => {
    const time = e.target.value;
    //console.log(time);
    this.setState({
      selectedTime: time,
    });
  };

  handleTimeEndChange = (e) => {
    const timeEnd = e.target.value;
    this.setState({
      selectedTimeEnd: timeEnd,
    });
  };

  handleServicesChange = (selectedOptions) => {
    const selectedService = selectedOptions.map((option) => option.label);
    this.setState({
      selectedServices: selectedService,
    });
  };

  handleButton = async () => {
    const {
      selectedEmployeeID,
      selectedCustomerID,
      selectedServices,
      selectedDate,
      selectedTime,
      selectedTimeEnd,
    } = this.state;

    // Überprüfen, ob eines der Felder leer ist
    if (
      !selectedEmployeeID ||
      !selectedCustomerID ||
      selectedServices.length === 0 ||
      !selectedDate ||
      !selectedTime ||
      !selectedTimeEnd
    ) {
      this.setState({ error: "Lütfen bütün boşlukları doldurun." });
      return;
    }

    const [hours, minutes] = selectedTime.split(":");
    const [hoursEnd, minutesEnd] = selectedTimeEnd.split(":");
    let dateTime = new Date(selectedDate);
    let dateTimeEnd = new Date(selectedDate);

    dateTime.setHours(parseInt(hours) + 3);
    dateTime.setMinutes(minutes);
    dateTimeEnd.setHours(parseInt(hoursEnd) + 3);
    dateTimeEnd.setMinutes(minutesEnd);

    dateTime = dateTime.toISOString().slice(0, 19).replace("T", " ");
    dateTimeEnd = dateTimeEnd.toISOString().slice(0, 19).replace("T", " ");

    let selectedServicesStr = "";
    selectedServices.forEach((service) => {
      if (selectedServicesStr.length === 0) {
        selectedServicesStr = service;
      } else {
        selectedServicesStr = selectedServicesStr + ";" + service;
      }
    });

    try {
      const resultMessage = await this.props.actions.createAppointment({
        customerID: selectedCustomerID,
        employeeID: selectedEmployeeID,
        dateTime,
        dateTimeEnd,
        selectedServices: selectedServicesStr,
      });

      if (
        resultMessage !== null &&
        resultMessage !== undefined &&
        !/^\d+$/.test(resultMessage)
      ) {
        //fail
        showFailureAlert("Randevu eklenemedi! "+resultMessage);
      } else {
        showSuccessAlert("Randevu eklendi!");
        const resultOfGet = await this.props.actions.getAppointmentById({id:resultMessage})
        //console.log("OK")
        if(resultOfGet["success"]){
          sendSMS(resultOfGet["appointment"][0]);
          planSMSReminder(resultOfGet["appointment"][0]);
        }else{
          showFailureAlert("SMS Hatası! "+resultOfGet["message"]);
        }
        
        this.props.reloadAppointments();
      }
    } catch (error) {
      console.error("Beklenmeyen bir hata oluştu! :", error);
    }

    this.closeModal();
  };

  putServicesToOptions = async () => {
    const services = this.props.services;
    let serviceOptions = [];
    services.forEach((service) => {
      let serviceOption = {
        value: service.id,
        label: service.name,
      };
      serviceOptions.push(serviceOption);
    });
    this.setState({
      serviceOptions,
    });
  };

  putEmployeesToOptions = async () => {
    const employees = this.props.employees;
    let employeeOptions = [];
    employees.forEach((employee) => {
      let employeeOption = {
        value: employee.id,
        label: employee.name,
      };
      employeeOptions.push(employeeOption);
    });
    this.setState({
      employeeOptions,
    });
  };

  putCustomersToOptions = async () => {
    const customers = this.props.customers;
    let customerOptions = [];
    customers.forEach((customer) => {
      let customerOption = {
        value: customer.id,
        label: customer.name,
      };
      customerOptions.push(customerOption);
    });
    this.setState({
      customerOptions,
    });
  };

  loadServices = async () => {
    const serviceSearchText = this.state.serviceSearchText;
    await this.props.actions.getServices({
      searchText: serviceSearchText,
      limitCount: 25,
      limitIndex: 1,
      existingServices: [],
    });

    this.putServicesToOptions();
  };

  loadEmployees = async () => {
    const employeeSearchText = this.state.employeeSearchText;
    await this.props.actions.getEmployees({
      searchText: employeeSearchText,
      limitCount: 25,
      limitIndex: 1,
      existingEmployees: [],
    });

    this.putEmployeesToOptions();
  };

  loadCustomers = async () => {
    const customerSearchText = this.state.customerSearchText;
    await this.props.actions.getCustomers({
      searchText: customerSearchText,
      limitCount: 25,
      limitIndex: 1,
      existingCustomers: [],
    });

    this.putCustomersToOptions();
  };

  loadData = async () => {
    this.loadCustomers();

    this.loadEmployees();

    this.loadServices();

    this.setState({
      isLoading: false,
    });
  };

  componentDidMount() {
    this.loadData();
  }

  renderTimeOptions() {
    const timeOptions = [];
    for (let hours = 8; hours < 21; hours++) {
      for (let minutes = 0; minutes < 60; minutes += 15) {
        const formattedTime = `${String(hours).padStart(2, "0")}:${String(
          minutes
        ).padStart(2, "0")}`;
        timeOptions.push(
          <option key={formattedTime} value={formattedTime}>
            {formattedTime}
          </option>
        );
      }
    }
    return timeOptions;
  }

  handleServiceInputChange = (serviceSearchText) => {
    // Case-insensitive search
    this.setState({
      serviceSearchText,
    });
    this.loadServices();
  };

  handleEmployeeInputChange = (employeeSearchText) => {
    // Case-insensitive search
    this.setState({
      employeeSearchText,
    });
    this.loadEmployees();
  };

  handleCustomerInputChange = (customerSearchText) => {
    // Case-insensitive search
    this.setState({
      customerSearchText,
    });
    this.loadCustomers();
  };

  closeModal = () => {
    this.props.actions.updateModal({ isModalOpen: false });
  };

  render() {
    const {
      isLoading,
      customerOptions,
      employeeOptions,
      serviceOptions,
      error,
    } = this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div style={{ width: "90%", margin: "20px" }}>
        <div className="row">
          <Select
            className="selectionBox"
            placeholder="Danışan seçiniz"
            options={customerOptions}
            onChange={this.handleCustomerChange}
            onInputChange={this.handleCustomerInputChange}
            filterOption={null}
          ></Select>
          <Select
            className="selectionBox"
            placeholder="Çalışan seçiniz"
            options={employeeOptions}
            onChange={this.handleEmployeeChange}
            onInputChange={this.handleEmployeeInputChange}
            filterOption={null}
          ></Select>
          <Input
            className="datePicker"
            name="date"
            placeholder="date placeholder"
            type="date"
            onChange={this.handleDateChange}
          />
          <Input
            className="timePicker"
            name="time"
            placeholder="time placeholder"
            type="select"
            style={{ marginTop: "10px" }}
            onChange={this.handleTimeChange}
          >
            {this.renderTimeOptions()}
          </Input>
          <Input
            className="timePicker"
            name="timeEnd"
            placeholder="timeEnd placeholder"
            type="select"
            style={{ marginTop: "10px" }}
            onChange={this.handleTimeEndChange}
          >
            {this.renderTimeOptions()}
          </Input>
          <Select
            className="multiSelectionBox"
            isMulti="true"
            placeholder="Hizmet seçiniz"
            options={serviceOptions}
            onChange={this.handleServicesChange}
            onInputChange={this.handleServiceInputChange}
            filterOption={null}
          />
          <Button
            className="appointmentModalButton"
            onClick={this.handleButton}
          >
            {" "}
            Randevu ekle
          </Button>
          <a
            style={{ marginLeft: "25px", textDecoration: "underline" }}
            onClick={this.closeModal}
          >
            Iptal
          </a>
        </div>
        {error && (
          <div style={{ marginLeft: "10px", color: "red", fontWeight: "bold" }}>
            {error}
          </div>
        )}{" "}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    employees: state.employeeReducer,
    customers: state.customerReducer,
    services: state.serviceReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createAppointment: bindActionCreators(
        appointmentActions.createAppointment,
        dispatch
      ),
      getAppointmentById: bindActionCreators(appointmentActions.getAppointmentById, dispatch),
      getEmployees: bindActionCreators(employeeActions.getEmployees, dispatch),
      getCustomers: bindActionCreators(customerActions.getCustomers, dispatch),
      getServices: bindActionCreators(serviceActions.getServices, dispatch),
      updateModal: bindActionCreators(modalActions.updateModal, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppointment);
