import * as actionsTypes from "./actionTypes";
import * as dbInfo from "./dbInfo";

export function getSuccess(employees) {
  return { type: actionsTypes.GET_EMPLOYEES_SUCCESS, payload: employees };
}

export function getFailure(message) {
  return { type: actionsTypes.GET_EMPLOYEES_FAILURE, payload: message };
}

export function updateSelectedEmployeeIdSuccess(selectedEmployeeId) {
  return { type: actionsTypes.UPDATE_SELECTED_EMPLOYEE_ID_SUCCESS, payload: selectedEmployeeId}
}

export function updateSelectedEmployeeSuccess(selectedEmployee) {
  return {
    type: actionsTypes.UPDATE_SELECTED_EMPLOYEE_SUCCESS,
    payload: selectedEmployee,
  };
}

export function getEmployees({searchText, limitCount, limitIndex, existingEmployees}) {
  return function (dispatch) {
    let url = "https://admin.beautyandrelaxyalova.com/barWebApp/getEmployeesLimited.php";
    let body = {
      searchText: searchText,
      limitIndex: limitIndex,
      limitCount: limitCount,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log("REsuolt OF eMployee : "+result);
        if (result["success"] === true) {
          const newEmployees = existingEmployees.concat(result["employees"]);
          return dispatch(getSuccess(newEmployees));
        } else {
          return result["message"];
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function getAllEmployees({
  searchText,
}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/getEmployees.php";
    let body = {
      searchText,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log("REsuolt OF eMployee : "+result);
        if (result["success"] === true) {
          return dispatch(getSuccess(result["employees"]));
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function getEmployeeById(employeeID) {
  let url =
    "https://admin.beautyandrelaxyalova.com/barWebApp/getEmployeeById.php";
  let body = {
    employeeID: employeeID,
    dbUser: dbInfo.DB_USER,
    dbPassword: dbInfo.DB_PASS,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result["success"] === true) {
        const employee = result["employee"][0];
        return employee;
      } else {
        return result["message"];
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
}

export function createEmployee({employeeName, employeeSurname, employeeGender, employeeTelephone, employeeBirthday}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/createEmployee.php";
    let body = {
      name: employeeName,
      surname: employeeSurname,
      gender: employeeGender,
      telephone: employeeTelephone,
      birthday: employeeBirthday,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}


export function updateSelectedEmployeeId({ selectedEmployeeId }) {
  return function (dispatch) {
    return dispatch(updateSelectedEmployeeIdSuccess(selectedEmployeeId));
  };
}

export function updateSelectedEmployee({ selectedEmployee }) {
  return function (dispatch) {
    return dispatch(updateSelectedEmployeeSuccess(selectedEmployee));
  };
}

export function updateEmployee({
  employeeID,
  name,
  surname,
  telephone,
}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/updateEmployee.php";

    let body = {
      employeeID,
      name,
      surname,
      telephone,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function deleteEmployee({ employeeID }) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/deleteEmployee.php";

    let body = {
      employeeID,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}
//response.json()
//dispatch(getProductsSuccess(result))
