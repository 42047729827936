import "../root/App.css";

import React, { Component } from 'react'
import ReactModal from "react-modal";
import CreateEmployee from './CreateEmployee';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as filterActions from "../../redux/actions/filterActions";
import * as employeeActions from "../../redux/actions/employeeActions";
import * as modalAction from "../../redux/actions/modalActions";

export class FilterEmployee extends Component {
  
  handleChange = async (e) => {
    await this.props.actions.updateSearchText({ searchText: e.target.value });
    await this.props.actions.resetLimitIndex();
    await this.props.actions.resetMoreBtn();

    const searchVal = this.props.searchText;
    const limitIndex = this.props.limitIndex;
    const limitCount = this.props.limitCount;
    await this.props.actions.getEmployees({
      searchText: searchVal,
      limitCount,
      limitIndex,
      existingEmployees: [],
    });
  };

  refreshEmployees = async () => {
    await this.props.actions.resetLimitIndex();
    await this.props.actions.resetSearchText();
    await this.props.actions.resetMoreBtn();

    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchText = this.props.searchText;

    await this.props.actions.getEmployees({
      searchText,
      limitCount,
      limitIndex,
      existingEmployees: [],
    });

    this.setState({
      isLoading: false,
    });
  };

  openModal = () => {
    this.props.actions.updateModal({ isModalOpen: true });
  };

  closeModal = () => {
    this.props.actions.updateModal({ isModalOpen: false });
  };

  render() {
    const { isModalOpen } = this.props;
    return (
      <div className="container">
        <div
          style={{
            padding: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <input
            className="searchField"
            onChange={this.handleChange}
            name="search"
            placeholder="Çalışan ara..."
            type="search"
          />
          <button className="customerButton" onClick={this.openModal}>
            Çalışan ekle
          </button>
          <ReactModal
            isOpen={isModalOpen}
            onRequestClose={this.closeModal}
            contentLabel="Create Employees"
            ariaHideApp={false}
            style={{
              overlay: {
                backgroundColor: "rgba(0,0,0,0.7)",
              },
              content: {
                color: "orange",
                backgroundColor: "rgba(0,0,0,0.5)",
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%,-50%)",
                border: "none",
                borderRadius: "10px",
              },
            }}
          >
            <CreateEmployee refreshEmployees={this.refreshEmployees}/>
          </ReactModal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchText: state.searchTextReducer,
    limitIndex: state.limitIndexReducer,
    limitCount: state.limitCountReducer,
    isModalOpen: state.modalReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getEmployees: bindActionCreators(employeeActions.getEmployees, dispatch),
      updateSearchText: bindActionCreators(
        filterActions.updateSearchText,
        dispatch
      ),
      resetLimitIndex: bindActionCreators(
        filterActions.resetLimitIndex,
        dispatch
      ),
      updateModal: bindActionCreators(modalAction.updateModal, dispatch),
      resetSearchText: bindActionCreators(
        filterActions.resetSearchText,
        dispatch
      ),
      resetMoreBtn: bindActionCreators(filterActions.resetMoreBtn, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterEmployee);
