import React, { Component } from "react";
import {
  Button,
  Row,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import ReactModal from "react-modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as loanActions from "../../redux/actions/loanActions";
import * as paymentActions from "../../redux/actions/paymentActions";
import * as filterActions from "../../redux/actions/filterActions";
import * as modalAction from "../../redux/actions/modalActions";
import "./LoansTable.css"
import CreatePayment from "../payments/CreatePayment";
import { AiOutlineDownload } from "react-icons/ai";
import generateInvoicePdf from "../payments/InvoicePDF";


class LoanTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedRow: null,
      isLoading: true,
      isDeleteModalOpen: false,
      selectedLoanDelete: null,
      isPaymentModalOpen: false,
      selectedPaymentDelete: null,
    };
  }

  refreshLoans = async () => {
    await this.props.actions.resetLimitIndex();
    await this.props.actions.resetSearchText();
    await this.props.actions.resetMoreBtn();

    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchText = this.props.searchText;

    await this.props.actions.getLoans({
      limitIndex,
      limitCount,
      searchText,
      existingLoans: new Map(),
    });

    this.setState({
      isLoading: false,
    });
  };

  async componentDidMount() {
    await this.refreshLoans();
  }

  getMoreLoans = async () => {
    await this.props.actions.increaseLimitIndex();

    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchText = this.props.searchText;

    const resultMessage = await this.props.actions.getLoans({
      limitIndex,
      limitCount,
      searchText,
      existingLoans: this.props.loans,
    });

    if (resultMessage === "empty") {
      this.props.actions.updateMoreBtn({isVisible:false})
    }
  };

  toggleModal = async (selectedLoan) => {
    await this.props.actions.updateSelectedLoan({ selectedLoan });
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: true });
  };

  closeModal = () => {
    // Close the modal and reset the selectedAppointment
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: false });
    this.props.actions.updateSelectedLoan({ selectedLoan: null });
  };

  toggleDeleteModal = (selectedLoan) => {
    this.setState({
      isDeleteModalOpen: true,
      selectedLoanDelete: selectedLoan[0],
    });
  };

  closeDeleteModal = () => {
    this.setState({
      isDeleteModalOpen: false,
      selectedLoanDelete: null,
    });
  };

  handleDeleteLoan = async () => {
    const { selectedLoanDelete } = this.state;
    await this.props.actions.deleteLoan({
      loanID: selectedLoanDelete.id,
    });

    this.setState({
      selectedLoanDelete: null,
      isDeleteModalOpen: false,
    });
    this.refreshLoans();
  };

  togglePaymentModal = (selectedPayment) => {
    this.setState({
      isPaymentModalOpen: true,
      selectedPaymentDelete: selectedPayment,
    });
  };

  closePaymentModal = () => {
    this.setState({
      isPaymentModalOpen: false,
      selectedPaymentDelete: null,
    });
  };

  handleDeletePayment = async () => {
    const { selectedPaymentDelete } = this.state;

    await this.props.actions.deletePayment({
      paymentID: selectedPaymentDelete.paymentId,
    });

    this.setState({
      selectedPaymentDelete: null,
      isPaymentModalOpen: false,
    });
    this.refreshLoans();
  };

  handleInvoiceDownload = async (payment) => {
    // Generate the invoice PDF
    generateInvoicePdf({ payment });
  };

  handleRowClick = (index) => {
    this.setState((prevState) => ({
      expandedRow: prevState.expandedRow === index ? null : index,
    }));
  };

  getRemainingAmount = (loan) => {
    let totalPaidAmount = 0;
    let remaining = 0;
    loan.forEach(payment => {
      if(payment.paymentId) {
        totalPaidAmount += parseInt(payment.paymentAmount);
        remaining = parseInt(payment.amount);
      }
    });
    //console.log("Loan : "+JSON.stringify(loan))
    //console.log("Total Paid : "+totalPaidAmount);
    //console.log("Remaining : "+remaining);
    return remaining-totalPaidAmount;
  }

  render() {
    const daysOfWeek = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];

    const {
      isLoading,
      isDeleteModalOpen,
      selectedLoanDelete,
      isPaymentModalOpen,
      selectedPaymentDelete,
    } = this.state;
    const { isMoreBtnVisible, loans, isUpdateModalOpen, selectedLoan } =
      this.props;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    //TODO: Burda Basliklari ayarlarsin, ben sadece simdilik "Appointments" deki tabloyu aldim
    //buraya aldim(Çatallı), ==> OK, Adamsın(Derdo)
    return (
      <div class="container">
        <table class="responsive-table">
          <thead>
            <tr>
              <th>#</th>
              <th>İsim</th>
              <th>Soyisim</th>
              <th>Açıklama</th>
              <th>Tutar</th>
              <th>Tarih</th>
              <th>Saat</th>
            </tr>
          </thead>
          <tbody>
            {Array.from(loans.keys()).map((loanId, index) => {
              const isExpanded = this.state.expandedRow === index;
              const loan = this.props.loans.get(loanId);
              const remaining = this.getRemainingAmount(loan);
              return (
                <React.Fragment key={index}>
                  <tr onClick={() => this.handleRowClick(index)}>
                    <th scope="row">
                      <Row className="headerRow">
                        <h2>{index + 1}</h2>
                        <div>
                          <Button
                            className="button"
                            onClick={() => this.toggleModal(loan)}
                          >
                            +
                          </Button>
                          <Button
                            className="button"
                            onClick={() => this.toggleDeleteModal(loan)}
                          >
                            SİL
                          </Button>
                        </div>
                      </Row>
                    </th>
                    <td>{loan[0].customerName}</td>
                    <td>{loan[0].customerSurname}</td>
                    <td>{loan[0].description}</td>
                    <td>{loan[0].amount}</td>
                    <td>{new Date(loan[0].dateTime).toLocaleDateString()}</td>
                    <td>
                      {new Date(loan[0].dateTime).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </td>
                  </tr>
                  {isExpanded && (
                    <>
                      {loan.map((payment, index) => {
                        if (payment.paymentDateTime !== null) {
                          payment.remaining = remaining;

                          return (
                            <tr key={index}>
                              <td colSpan={9}>
                                {/* Payments  */}
                                <div className="loansExpandedContent">
                                  <div className="loansContentItem">
                                    <Row className="paymentItemRow">
                                      <p>
                                        Tarih:{" "}
                                        {new Date(
                                          payment.paymentDateTime
                                        ).toLocaleDateString()}{" "}
                                      </p>
                                    </Row>
                                    <Row className="paymentItemRow">
                                      <p>
                                        Açıklama: {payment.paymentDescripion}
                                      </p>
                                    </Row>
                                    <Row className="paymentItemRow">
                                      <p>Tutar:{payment.paymentAmount}</p>
                                    </Row>
                                  </div>
                                  <div className="loansContentItem">
                                    {/* Delete Payment and download payment!!!!!!!!!!! */}
                                    <Row>
                                      <Button
                                        className="button"
                                        onClick={() =>
                                          this.togglePaymentModal(payment)
                                        }
                                      >
                                        SİL
                                      </Button>
                                    </Row>
                                    <Row>
                                      <Button
                                        className="button"
                                        onClick={() =>
                                          this.handleInvoiceDownload(payment)
                                        }
                                      >
                                        <AiOutlineDownload
                                          size={28}
                                        ></AiOutlineDownload>
                                      </Button>
                                    </Row>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        {isMoreBtnVisible && <Button onClick={this.getMoreLoans}>More</Button>}
        {selectedLoan && isUpdateModalOpen && (
          <ReactModal
            isOpen={isUpdateModalOpen}
            toggle={this.toggleModal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
          >
            <CreatePayment refreshLoans={this.refreshLoans} />
          </ReactModal>
        )}
        {selectedPaymentDelete && isPaymentModalOpen && (
          <ReactModal
            isOpen={isPaymentModalOpen}
            onRequestClose={this.closePaymentModal}
            ariaHideApp={false}
          >
            <ModalHeader toggle={this.closePaymentModal}>Ödeme Sil</ModalHeader>
            <ModalBody style={{ marginTop: "50px" }}>
              <h5>Seçili Ödeme Silinecektir.</h5>
              <h5>Emin misiniz?</h5>
            </ModalBody>
            <ModalFooter
              style={{
                marginTop: "50px",
                position: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* Update button */}
              <Button
                style={{ backgroundColor: "#f58634", border: "none" }}
                onClick={this.handleDeletePayment}
              >
                Sil
              </Button>
              {/* Cancel button */}
              <Button color="secondary" onClick={this.closePaymentModal}>
                İptal
              </Button>
            </ModalFooter>
          </ReactModal>
        )}
        {selectedLoanDelete && isDeleteModalOpen && (
          <ReactModal
            isOpen={isDeleteModalOpen}
            onRequestClose={this.closeDeleteModal}
            ariaHideApp={false}
          >
            <ModalHeader toggle={this.closeDeleteModal}>Alacak Sil</ModalHeader>
            <ModalBody style={{ marginTop: "50px" }}>
              <h5>Seçili Alacak Silinecektir.</h5>
              <h5>Emin misiniz?</h5>
            </ModalBody>
            <ModalFooter
              style={{
                marginTop: "50px",
                position: "flex",
                justifyContent: "space-evenly",
              }}
            >
              {/* Delete button */}
              <Button
                style={{ backgroundColor: "#f58634", border: "none" }}
                onClick={this.handleDeleteLoan}
              >
                Sil
              </Button>
              {/* Cancel button */}
              <Button color="secondary" onClick={this.closeDeleteModal}>
                İptal
              </Button>
            </ModalFooter>
          </ReactModal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    loans: state.loanReducer,
    selectedLoan: state.selectedLoanReducer,
    limitIndex: state.limitIndexReducer,
    limitCount: state.limitCountReducer,
    searchText: state.searchTextReducer,
    isUpdateModalOpen: state.modalUpdateReducer,
    isMoreBtnVisible: state.moreBtnReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getLoans: bindActionCreators(loanActions.getLoans, dispatch),
      updateSelectedLoan: bindActionCreators(
        loanActions.updateSelectedLoan,
        dispatch
      ),
      deleteLoan: bindActionCreators(loanActions.deleteLoan, dispatch),
      deletePayment: bindActionCreators(paymentActions.deletePayment, dispatch),
      increaseLimitIndex: bindActionCreators(
        filterActions.increaseLimitIndex,
        dispatch
      ),
      resetLimitIndex: bindActionCreators(
        filterActions.resetLimitIndex,
        dispatch
      ),
      updateUpdateModal: bindActionCreators(
        modalAction.updateUpdateModal,
        dispatch
      ),
      resetSearchText: bindActionCreators(
        filterActions.resetSearchText,
        dispatch
      ),
      updateMoreBtn: bindActionCreators(filterActions.updateMoreBtn, dispatch),
      resetMoreBtn: bindActionCreators(filterActions.resetMoreBtn, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoanTable);
