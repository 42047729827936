import { combineReducers } from "redux";
import authReducer from "./authReducer";
import appointmentReducer from "./appoinments/appointmentReducer";
import isExpiredReducer from "./appoinments/isExpiredReducer";
import customerReducer from "./customers/customerReducer";
import employeeReducer from "./employees/employeeReducer";
import selectedEmployeeIdReducer from "./employees/selectedEmployeeIdReducer";
import serviceReducer from "./services/serviceReducer";
import limitIndexReducer from "./filter/limitIndexReducer";
import limitCountReducer from "./filter/limitCountReducer";
import searchTextReducer from "./filter/searchTextReducer";
import appointmentCalendarReducer from "./appoinments/appointmentCalendarReducer";
import firstDayOfWeekReducer from "./appoinments/firstDayOfWeekReducer";
import loanReducer from "./loans/loanReducer"
import selectedLoanReducer from "./loans/selectedLoanReducer";
import modalReducer from "./modalReducer";
import modalUpdateReducer from "./modalUpdateReducer";
import moreBtnReducer from "./filter/moreBtnReducer";
import selectedAppointmentReducer from "./appoinments/selectedAppointmentReducer";
import selectedEmployeeReducer from "./employees/selectedEmployeeReducer";
import selectedCustomerReducer from "./customers/selectedCustomerReducer";
import selectedServiceReducer from "./services/selectedServiceReducer";
import errorMessageReducer from  "./filter/errorMessageReducer";

const rootReducer = combineReducers({
  authReducer,
  appointmentReducer,
  isExpiredReducer,
  appointmentCalendarReducer,
  firstDayOfWeekReducer,
  customerReducer,
  employeeReducer,
  selectedEmployeeIdReducer,
  serviceReducer,
  limitIndexReducer,
  limitCountReducer,
  searchTextReducer,
  loanReducer,
  modalReducer,
  modalUpdateReducer,
  moreBtnReducer,
  selectedAppointmentReducer,
  selectedEmployeeReducer,
  selectedCustomerReducer,
  selectedServiceReducer,
  selectedLoanReducer,
  errorMessageReducer,
});

export default rootReducer;
