import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function loanReducer(state = initialState.loans, action) {
  switch (action.type) {
    case actionTypes.GET_LOANS_SUCCESS:
      return action.payload;
    case actionTypes.CLEAR_LOANS_SUCCESS:
      return [];
    default:
      return state;
  }
}
