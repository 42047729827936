import React, { Component } from 'react'
import CalendarTable from './CalendarTable';
import FilterCalender from './FilterCalender';
import withAuthRedirect from '../../hoc/withAuthRedirect';

export class Calendar extends Component {
  render() {
    return (
      <div>
        <FilterCalender></FilterCalender>
        <CalendarTable></CalendarTable>
      </div>
    );
  }
}

export default withAuthRedirect(Calendar);
