import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as serviceActions from "../../redux/actions/serviceActions";
import * as filterActions from "../../redux/actions/filterActions";
import * as modalAction from "../../redux/actions/modalActions";
import UpdateService from "./UpdateService";
import ReactModal from "react-modal";

export class ServicesTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  refreshServices = async () => {
    await this.props.actions.resetLimitIndex();
    await this.props.actions.resetSearchText();
    await this.props.actions.resetMoreBtn();

    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchText = this.props.searchText;
    
    await this.props.actions.getServices({
      searchText,
      limitCount,
      limitIndex,
      existingServices: [],
    });

    this.setState({
      isLoading: false,
    });
  }
  async componentDidMount() {
    await this.refreshServices();
  }

  getMoreServices = async () => {
    await this.props.actions.increaseLimitIndex();

    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchText = this.props.searchText;

    const resultMessage = await this.props.actions.getServices({
      limitIndex,
      limitCount,
      searchText,
      existingServices: this.props.services,
    });

    if(resultMessage==="empty") {
      this.props.actions.updateMoreBtn({isVisible: false});
    }
  };

  toggleModal = async (selectedService) => {
    await this.props.actions.updateSelectedService({ selectedService });
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: true });
  };

  closeModal = () => {
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: false });
    this.props.actions.updateSelectedService({ selectedService: null });
  };

  render() {
    const { isLoading} =
      this.state;

    const { isMoreBtnVisible, isUpdateModalOpen, selectedService } = this.props;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div class="container">
        <table class="responsive-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Hizmetler</th>
            </tr>
          </thead>
          <tbody>
            {this.props.services.map((service, index) => {
              return (
                <React.Fragment key={index}>
                  <tr onClick={() => this.toggleModal(service)}>
                    <th scope="row">{index + 1}</th>
                    <td>{service.name}</td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
        {isMoreBtnVisible && (
          <Button onClick={this.getMoreServices}>More</Button>
        )}
        {/* Modal for update and delete buttons */}
        {selectedService && (
          <ReactModal
            isOpen={isUpdateModalOpen}
            toggle={this.toggleModal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
          >
            <UpdateService refreshServices={this.refreshServices} />
          </ReactModal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.authReducer,
    services: state.serviceReducer,
    selectedService: state.selectedServiceReducer,
    searchText: state.searchTextReducer,
    limitCount: state.limitCountReducer,
    limitIndex: state.limitIndexReducer,
    isUpdateModalOpen: state.modalUpdateReducer,
    isMoreBtnVisible: state.moreBtnReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getServices: bindActionCreators(serviceActions.getServices, dispatch),
      updateSelectedService: bindActionCreators(
        serviceActions.updateSelectedService,
        dispatch
      ),
      increaseLimitIndex: bindActionCreators(
        filterActions.increaseLimitIndex,
        dispatch
      ),
      resetLimitIndex: bindActionCreators(
        filterActions.resetLimitIndex,
        dispatch
      ),
      updateUpdateModal: bindActionCreators(
        modalAction.updateUpdateModal,
        dispatch
      ),
      resetSearchText: bindActionCreators(
        filterActions.resetSearchText,
        dispatch
      ),
      updateMoreBtn: bindActionCreators(filterActions.updateMoreBtn, dispatch),
      resetMoreBtn: bindActionCreators(filterActions.resetMoreBtn, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesTable);
