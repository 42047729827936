import React, { Component } from 'react'
import AppointmentTable from './AppointmentTable';
import FilterAppointments from './FilterAppointments';
import withAuthRedirect from '../../hoc/withAuthRedirect';


export class Appointments extends Component {
  render() {
    return (
      <div>
        <FilterAppointments></FilterAppointments>
        <AppointmentTable></AppointmentTable>
      </div>
    );
  }
}

export default withAuthRedirect(Appointments);

