import React, { Component } from "react";
import { Button, Row } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appointmentActions from "../../redux/actions/appointmentActions";
import * as employeeActions from "../../redux/actions/employeeActions"
import "./CalendarTable.css";
import "../root/App.css";

// eslint-disable-next-line import/no-anonymous-default-export
export class CalendarTable extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const dayOfWeek = date.getDay();
    const diff = date.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    const monday = new Date(date.setDate(diff));
    const days = this.generateDaysArray(monday);

    this.state = {
      week: 0,
      days,
      timeRows: [],
      isLoading: true,
    };
  }

  generateDaysArray(monday) {
    const days = [];
    const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    for (let i = 0; i < daysOfWeek.length; i++) {
      const day = new Date(monday);
      day.setDate(monday.getDate() + i);
      days.push(day);
    }
    return days;
  }

  async getAppointments(days) {
    const firstDay = new Date(days[0]);
    const employeeId = this.props.selectedEmployeeId;
    await this.props.actions.getAppointmentsForCalendar({
      startDate: firstDay,
      employeeId,
    });
    //console.log(JSON.stringify(this.props.appointments));
    // Create an empty two-dimensional map
    const appointments = this.props.appointments;
    //console.log(JSON.stringify(appointments));
    return appointments;
  }

  setRowsToList = async () => {
    const tableMap = this.props.appointmentsForCalendar;
    const timeRows = [];
    let key = 0;
    outerloop: for (let i = 10; i < 21; i++) {
      for (let j = 0; j < 60; j += 15) {
        timeRows.push(
          <tr key={key}>
            <th className="timeRow2">{i + "." + (j === 0 ? "00" : j)}</th>
            <td className="dayRow2">
              {tableMap[1] && tableMap[1][`${i}.${j}`] !== undefined
                ? tableMap[1][`${i}.${j}`]["customerName"]
                : null}
            </td>

            <td className="dayRow2">
              {tableMap[2] && tableMap[2][`${i}.${j}`] !== undefined
                ? tableMap[2][`${i}.${j}`]["customerName"]
                : null}
            </td>
            <td className="dayRow2">
              {tableMap[3] && tableMap[3][`${i}.${j}`] !== undefined
                ? tableMap[3][`${i}.${j}`]["customerName"]
                : null}
            </td>
            <td className="dayRow2">
              {tableMap[4] && tableMap[4][`${i}.${j}`] !== undefined
                ? tableMap[4][`${i}.${j}`]["customerName"]
                : null}
            </td>
            <td className="dayRow2">
              {tableMap[5] && tableMap[5][`${i}.${j}`] !== undefined
                ? tableMap[5][`${i}.${j}`]["customerName"]
                : null}
            </td>
            <td className="dayRow2">
              {tableMap[6] && tableMap[6][`${i}.${j}`] !== undefined
                ? tableMap[6][`${i}.${j}`]["customerName"]
                : null}
            </td>
            <td className="dayRow2">
              {tableMap[7] && tableMap[7][`${i}.${j}`] !== undefined
                ? tableMap[7][`${i}.${j}`]["customerName"]
                : null}
            </td>
          </tr>
        );
        key++;
        if (i === 20) {
          break outerloop;
        }
      }
    }

    this.setState({
      timeRows,
    })
  };

  async componentDidMount() {
    const { days } = this.state;
    const { limitCount, limitIndex } = this.props;

    try {
      await this.props.actions.getEmployees({
        searchText: "",
        limitCount,
        limitIndex,
        existingEmployees: [],
      });
      await this.props.actions.updateSelectedEmployeeId({
        selectedEmployeeId: this.props.employees[0].id,
      });
      await this.getAppointments(days);

      await this.setRowsToList();

      this.setState({
        week: 0,
        isLoading: false,
      });

    } catch (error) {
      console.error(error);
    }
  }

  async updateWeek(forward) {
    const { week, days } = this.state;
    const dayIncrement = forward ? 7 : -7;
    const newMonday = new Date(
      days[0].getTime() + dayIncrement * 24 * 60 * 60 * 1000
    );
    const newDays = this.generateDaysArray(newMonday);

    this.setState(
      { week: week + (forward ? 1 : -1), days: newDays },
      async () => {
        try {
          await this.getAppointments(this.state.days);
          this.setState({
            week: 0,
          });
        } catch (error) {
          console.error(error);
        }
      }
    );
    this.props.actions.updateFirstDayOfWeek({ firstDay: newDays[0] });
  }

  formatDate(date) {
    const day = date.getDate();
    const month = date.getMonth() + 1; // Months are zero-based
    return `${day}.${month}`;
  }

  componentDidUpdate(prevProps) {
    // Überprüfen Sie, ob sich die relevanten Props geändert haben
    if (prevProps.appointmentsForCalendar !== this.props.appointmentsForCalendar) {
      // Aktualisieren Sie die Liste der Zeilen basierend auf den neuen Daten
      this.setRowsToList();
    }
  }

  render() {
    const {timeRows, isLoading} = this.state;

    if(isLoading) {
      return (
        <div>Loading...</div>
      )
    }

    return (
      <div>
        <Row className="row">
          <Button
            onClick={() => this.updateWeek(false)}
            className="button weekNav"
          >
            &lt;
          </Button>
          <Button
            onClick={() => this.updateWeek(true)}
            className="button weekNav"
          >
            &gt;
          </Button>
          <h4 className="weekText">
            {`${this.formatDate(this.state.days[0])} - ${this.formatDate(
              this.state.days[6]
            )}`}
          </h4>
        </Row>
        <div className="calendarOuterContainer">
          <table className="sticky-thc">
            <thead className="deneme">
              <tr className="header">
                <th className="dayRow"></th>{" "}
                {/* Empty cell for the top-left corner */}
                <th className="dayRow">
                  {/* <Col> */}
                    Pzrt
                    <p>{this.formatDate(this.state.days[0])}</p>
                  {/* </Col> */}
                </th>
                <th className="dayRow">
                  {/* <Col> */}
                    Sal
                    <p>{this.formatDate(this.state.days[1])}</p>
                  {/* </Col> */}
                </th>
                <th className="dayRow">
                  {/* <Col> */}
                    Car
                    <p>{this.formatDate(this.state.days[2])}</p>
                  {/* </Col> */}
                </th>
                <th className="dayRow">
                  {/* <Col> */}
                    Per
                    <p>{this.formatDate(this.state.days[3])}</p>
                  {/* </Col> */}
                </th>
                <th className="dayRow">
                  {/* <Col> */}
                    Cum
                    <p>{this.formatDate(this.state.days[4])}</p>
                  {/* </Col> */}
                </th>
                <th className="dayRow">
                  {/* <Col> */}
                    Cmrt
                    <p>{this.formatDate(this.state.days[5])}</p>
                  {/* </Col> */}
                </th>
                <th className="dayRow">
                  {/* <Col> */}
                    Paz
                    <p>{this.formatDate(this.state.days[6])}</p>
                  {/* </Col> */}
                </th>
              </tr>
            </thead>
            <tbody>
              {timeRows}
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    appointmentsForCalendar: state.appointmentCalendarReducer,
    isExpiredAppointments: state.isExpiredReducer,
    employees: state.employeeReducer,
    selectedEmployeeId: state.selectedEmployeeIdReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getAppointmentsForCalendar: bindActionCreators(
        appointmentActions.getAppointmentsForCalendar,
        dispatch
      ),
      updateFirstDayOfWeek: bindActionCreators(
        appointmentActions.updateFirstDayOfWeek,
        dispatch
      ),
      getEmployees: bindActionCreators(employeeActions.getEmployees, dispatch),
      updateSelectedEmployeeId: bindActionCreators(
        employeeActions.updateSelectedEmployeeId,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarTable);
