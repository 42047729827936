import * as actionsTypes from "./actionTypes";

export function updateModalSuccess(isModalOpen) {
  return {
    type: actionsTypes.UPDATE_MODAL_STATE,
    payload: isModalOpen
  }
}

export function updateUpdateModalSuccess(isUpdateModalOpen) {
  return {
    type: actionsTypes.UPDATE_UPDATE_MODAL_STATE,
    payload: isUpdateModalOpen,
  };
}

export function updateModal({isModalOpen}) {
  return function(dispatch) {
    console.log("updateModal: " + isModalOpen)
    return dispatch(updateModalSuccess(isModalOpen));
  }
}

export function updateUpdateModal({isUpdateModalOpen}) {
  return function(dispatch) {
    return dispatch(updateUpdateModalSuccess(isUpdateModalOpen));
  }
}
//response.json()
//dispatch(getProductsSuccess(result))
