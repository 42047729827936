import React, { Component } from "react";
import { Button, Input, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as employeeActions from "../../redux/actions/employeeActions";
import * as modalActions from "../../redux/actions/modalActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showSuccessAlert } from "../root/AlertService";

export class UpdateEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedName: "",
      selectedSurname: "",
      selectedTelephone: "",
    };
  }

  handleUpdateEmployee = async () => {
    const { selectedName, selectedSurname, selectedTelephone } = this.state;

    const employee = this.props.selectedEmployee;

    /* console.log(
      "Selected \n" +
        selectedName +
        "\n" +
        selectedSurname +
        "\n" +
        selectedTelephone +
        "\n"
    ); */

    try {
      await this.props.actions.updateEmployee({
        employeeID: employee.id,
        name: selectedName,
        surname: selectedSurname,
        telephone: selectedTelephone,
      });

      showSuccessAlert("Çalışan güncellendi!");
      this.props.refreshEmployees();
    } catch (error) {
      console.error("Beklenmeyen bir hata oluştu! :", error);
    }

    this.closeModal();
  };

  handleDeleteEmployee = async () => {
    const employeeID = this.props.selectedEmployee.id;

    try {
      await this.props.actions.deleteEmployee({
        employeeID,
      });
  
      showSuccessAlert("Çalışan silindi!");
      this.props.refreshEmployees();
    } catch (error) {
      console.error("Beklenmeyen bir hata oluştu! :", error);
    }
    
    this.closeModal();
  };

  closeModal = () => {
    // Close the modal and reset the selectedAppointment
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: false });
    this.props.actions.updateSelectedEmployee({ selectedEmployee: null });
  };

  putDataToFields = async () => {
    const employee = this.props.selectedEmployee;

    //console.log(JSON.stringify(employee));

    this.setState({
      selectedName: employee.name,
      selectedSurname: employee.surname,
      selectedTelephone: employee.telephone,
    });
  };

  initializeData = async () => {
    await this.putDataToFields();
    this.setState({
      isLoading: false,
    });
  };

  async componentDidMount() {
    await this.initializeData();
  }

  handleChange = (e) => {
    const { name, value, type, checked, maxLength } = e.target;
    //console.log(e.target);
    if (type === "number") {
      //console.log("max : " + maxLength);
      if (value.length > maxLength) {
        let inputValue = value.slice(0, maxLength);
        this.setState({ [name]: inputValue });
      } else {
        if (value === "0") {
          this.setState({ [name]: "" });
        } else {
          this.setState({ [name]: value });
        }
      }
    } else {
      const inputValue = value;
      this.setState({ [name]: inputValue });
    }
  };

  render() {
    const { isLoading, selectedName, selectedSurname, selectedTelephone } =
      this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <ModalHeader toggle={this.closeModal}>Çalışan Bilgileri</ModalHeader>
        <ModalBody>
          <Input
            className="textField"
            name="selectedName"
            placeholder="İsim"
            type="text"
            onChange={this.handleChange}
            value={selectedName}
          />
          <Input
            className="textField"
            name="selectedSurname"
            placeholder="Soyisim"
            type="text"
            onChange={this.handleChange}
            value={selectedSurname}
          />
          <Input
            className="textField"
            name="selectedTelephone"
            placeholder="Telefon"
            type="number"
            maxLength={10}
            onChange={this.handleChange}
            value={selectedTelephone}
          />
          <Input
            className="datePicker"
            name="date"
            placeholder="date placeholder"
            type="date"
            disabled="true"
            defaultValue={this.props.selectedEmployee.birthday}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: "#f58634", border: "none" }}
            onClick={this.handleUpdateEmployee}
          >
            Güncelle
          </Button>
          <Button
            color="danger"
            style={{ border: "none", margin: "10px" }}
            onClick={this.handleDeleteEmployee}
          >
            Sil
          </Button>
          <Button color="secondary" onClick={this.closeModal}>
            İptal
          </Button>
        </ModalFooter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedEmployee: state.selectedEmployeeReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateEmployee: bindActionCreators(
        employeeActions.updateEmployee,
        dispatch
      ),
      deleteEmployee: bindActionCreators(
        employeeActions.deleteEmployee,
        dispatch
      ),
      updateSelectedEmployee: bindActionCreators(
        employeeActions.updateSelectedEmployee,
        dispatch
      ),
      updateUpdateModal: bindActionCreators(
        modalActions.updateUpdateModal,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateEmployee);
