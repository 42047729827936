import React, { Component } from "react";
import { Button, Input, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as customerActions from "../../redux/actions/customerActions";
import * as modalActions from "../../redux/actions/modalActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showSuccessAlert } from "../root/AlertService";

export class UpdateCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      selectedName: "",
      selectedSurname: "",
      selectedTelephone: "",
    };
  }

  handleUpdateCustomer = async () => {
    const { selectedName, selectedSurname, selectedTelephone } = this.state;
    const customer = this.props.selectedCustomer;

    try {
      await this.props.actions.updateCustomer({
        customerID: customer.id,
        name: selectedName,
        surname: selectedSurname,
        telephone: selectedTelephone,
      });

      this.props.refreshCustomers();
      showSuccessAlert("Danışan güncellendi!");
    } catch (error) {
      console.error("Beklenmeyen bir hata oluştu! :", error);
    }

    this.closeModal();
  };

  handleDeleteCustomer = async () => {
    const customerID = this.props.selectedCustomer.id;

    try {
      await this.props.actions.deleteCustomer({
        customerID: customerID,
      });

      showSuccessAlert("Danışan silindi!");
      this.props.refreshCustomers();
    } catch (error) {
      console.error("Müşteriyi silerken bir hata oluştu! :", error);
    }
    
    this.closeModal();
  };

  closeModal = () => {
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: false });
    this.props.actions.updateSelectedCustomer({ selectedCustomer: null });
  };

  putDataToFields = async () => {
    const customer = this.props.selectedCustomer;

    //console.log(JSON.stringify(customer));

    this.setState({
      selectedName: customer.name,
      selectedSurname: customer.surname,
      selectedTelephone: customer.telephone,
    });
  };

  initializeData = async () => {
    await this.putDataToFields();
    this.setState({
      isLoading: false,
    });
  };

  async componentDidMount() {
    await this.initializeData();
  }

  handleChange = (e) => {
    const { name, value, type, checked, maxLength } = e.target;
    //console.log(e.target);
    if (type === "number") {
      //console.log("max : " + maxLength);
      if (value.length > maxLength) {
        let inputValue = value.slice(0, maxLength);
        this.setState({ [name]: inputValue });
      } else {
        if (value === "0") {
          this.setState({ [name]: "" });
        } else {
          this.setState({ [name]: value });
        }
      }
    } else {
      const inputValue = value;
      this.setState({ [name]: inputValue });
    }
  };

  render() {
    const { isLoading, selectedName, selectedSurname, selectedTelephone } =
      this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <ModalHeader toggle={this.closeModal}>Danışan Bilgileri</ModalHeader>
        <ModalBody>
          <Input
            className="textField"
            name="selectedName"
            placeholder="İsim"
            onChange={this.handleChange}
            value={selectedName}
          />
          <Input
            className="textField"
            name="selectedSurname"
            placeholder="Soyisim"
            onChange={this.handleChange}
            value={selectedSurname}
          />
          <Input
            className="textField"
            name="selectedTelephone"
            placeholder="Telefon"
            type="number"
            maxLength={10}
            onChange={this.handleChange}
            value={selectedTelephone}
          />
          <Input
            className="datePicker"
            name="date"
            placeholder="date placeholder"
            type="date"
            disabled="true"
            defaultValue={this.props.selectedCustomer.birthday}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ backgroundColor: "#f58634", border: "none" }}
            onClick={this.handleUpdateCustomer}
          >
            Güncelle
          </Button>
          <Button
            color="danger"
            style={{ border: "none", margin: "10px" }}
            onClick={this.handleDeleteCustomer}
          >
            Sil
          </Button>
          <Button
            color="secondary"
            style={{ border: "none" }}
            onClick={this.closeModal}
          >
            İptal
          </Button>
        </ModalFooter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedCustomer: state.selectedCustomerReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      updateCustomer: bindActionCreators(
        customerActions.updateCustomer,
        dispatch
      ),
      deleteCustomer: bindActionCreators(
        customerActions.deleteCustomer,
        dispatch
      ),
      updateSelectedCustomer: bindActionCreators(
        customerActions.updateSelectedCustomer,
        dispatch
      ),
      updateUpdateModal: bindActionCreators(
        modalActions.updateUpdateModal,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateCustomer);
