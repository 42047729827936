import * as actionsTypes from "./actionTypes";
import * as dbInfo from "./dbInfo";

export function loginSuccess() {
  return { type: actionsTypes.LOGIN_SUCCESS };
}

export function logOutSuccess() {
  return { type: actionsTypes.LOGOUT_SUCCESS };
}

export function invalidLogin(message) {
  return { type: actionsTypes.INVALID_LOGIN, payload: message};
}

export function login({ username, password }) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/tryToLoginBCrypt.php";
    let body = {
      username: username,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result)
        if(result["success"] === true) {
          return result["password"];
        }else {
          return result["message"];
        }

        })
      .catch((err) => {
        if (err.message.includes("Kullanıcı adı bulunamadı!")) {
          dispatch(invalidLogin("Kullanıcı adı bulunamadı!"));
        }
        console.log(err.message);
      });
  };
}

export function setLogin() {
  return function (dispatch) {
    //console.log("Bin da bin da bin da!")
    return dispatch(loginSuccess());
  };
}
//response.json()
//dispatch(getProductsSuccess(result))
