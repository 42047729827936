import React, { Component } from "react";
import CreateService from "./CreateService";
import FilterService from "./FilterService";
import ServicesTable from "./ServicesTable";
import withAuthRedirect from "../../hoc/withAuthRedirect";

export class Services extends Component {
  render() {
    return (
      <div>
        <CreateService></CreateService>
        <FilterService></FilterService>
        <ServicesTable></ServicesTable>
      </div>
    );
  }
}

export default withAuthRedirect(Services);
