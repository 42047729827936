import "../root/App.css";
import React, { Component } from "react";
import { Input, Label } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showSuccessAlert } from "../root/AlertService";

import * as customerActions from "../../redux/actions/customerActions";
import * as modalAction from "../../redux/actions/modalActions";

export class CreateCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerName: "",
      customerSurname: "",
      customerGender: false,
      customerTelephone: "",
      customerBirthday: "",
      error: "",
    };
  }

  handleChange = (e) => {
    const { name, value, type, checked, maxLength } = e.target;
    const inputValue = type === "checkbox" ? checked : value;
    if (type === "number") {
      //console.log("max : " + maxLength);
      if (value.length > maxLength) {
        let inputValue = value.slice(0, maxLength);
        this.setState({ [name]: inputValue });
      } else {
        if (value === "0") {
          this.setState({ [name]: "" });
        } else {
          this.setState({ [name]: value });
        }
      }
    } else {
      this.setState({ [name]: inputValue });
    }
  };

  handleChangeButton = () => {
    const {
      customerName,
      customerSurname,
      customerGender,
      customerTelephone,
      customerBirthday,
    } = this.state;

    try {
      if (
        !customerName ||
        !customerSurname ||
        !customerTelephone ||
        !customerBirthday
      ) {
        this.setState({ error: "Lütfen bütün boşlukları doldurun!" });
        return;
      }

      this.props.actions.createCustomer({
        customerName,
        customerSurname,
        customerGender,
        customerTelephone,
        customerBirthday,
      });
      
      showSuccessAlert("Danışan eklendi!");
    } catch (error) {
      this.setState({ error: "Bir hata oluştu! Tekrar deneyiniz!" });
    }

    this.props.refreshCustomers();
    this.closeModal();
  };

  closeModal = () => {
    this.props.actions.updateModal({ isModalOpen: false });
  };

  render() {
    const { error } = this.state;
    return (
      <div
        style={{
          margin: "10px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="row">
          <Input
            className="textField"
            name="customerName"
            placeholder="İsim"
            onChange={this.handleChange}
            value={this.state.customerName}
          />
          <Input
            className="textField"
            name="customerSurname"
            placeholder="Soyisim"
            onChange={this.handleChange}
            value={this.state.customerSurname}
          />
          <Label className="label">
            Erkek
            <Input
              className="checkBox"
              name="customerGender"
              type="checkbox"
              onChange={this.handleChange}
              checked={this.state.customerGender}
            />
          </Label>
        </div>
        <div className="row">
          <Input
            className="textField"
            name="customerTelephone"
            type="number"
            maxLength="10"
            placeholder="Telefon"
            onChange={this.handleChange}
            value={this.state.customerTelephone}
          />
          <Input
            className="textField"
            name="customerBirthday"
            placeholder="Doğum Tarihi"
            type="date"
            onChange={this.handleChange}
            value={this.state.customerBirthday}
          />
          <button className="button" onClick={this.handleChangeButton}>
            Danışan ekle
          </button>
          <a
            style={{ marginLeft: "-20px", textDecoration: "underline" }}
            onClick={this.closeModal}
          >
            Iptal
          </a>
        </div>
        {error && (
          <div
            style={{ marginLeft: "-10px", color: "red", fontWeight: "bold" }}
          >
            {error}
          </div>
        )}{" "}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createCustomer: bindActionCreators(
        customerActions.createCustomer,
        dispatch
      ),
      updateModal: bindActionCreators(modalAction.updateModal, dispatch),
    },
  };
}

export default connect(null, mapDispatchToProps)(CreateCustomer);
