import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function moreBtnReducer(
  state = initialState.isMoreBtnVisible,
  action
) {
  switch (action.type) {
    case actionTypes.UPDATE_MORE_BTN_SUCCESS:
      return action.payload;
    case actionTypes.RESET_MORE_BTN:
      return true;
    default:
      return state;
  }
}
