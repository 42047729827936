import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function isExpiredReducer(
  state = initialState.isExpiredAppointments,
  action
) {
  switch (action.type) {
    case actionTypes.UPDATE_EXPIRED_APPOINTMENTS:
      return action.payload;
    default:
      return state;
  }
}
