import * as actionsTypes from "./actionTypes";

export function increaseLimitIndexSuccess() {
  return {
    type: actionsTypes.UPDATE_LIMIT_INDEX
  };
}

export function resetLimitIndexSuccess() {
  return {
    type: actionsTypes.RESET_LIMIT_INDEX,
  };
}

export function updateSearchTextSuccess(searchText) {
  return {
    type: actionsTypes.UPDATE_SEARCH_TEXT,
    payload: searchText
  }
}

export function updateErroMessageSuccess(errorMessage) {
  return {
    type: actionsTypes.UPDATE_ERROR_MESSAGE,
    payload: errorMessage,
  };
}

export function updateMoreBtnSuccess(isVisible) {
  return { type: actionsTypes.UPDATE_MORE_BTN_SUCCESS, payload: isVisible };
}

export function resetSearchTextSuccess() {
  return {type: actionsTypes.RESET_SEARCH_TEXT}
}

export function resetMoreBtnSuccess() {
  return {type: actionsTypes.RESET_MORE_BTN}
}

export function increaseLimitIndex() {
  return function (dispatch) {
    return dispatch(increaseLimitIndexSuccess());
  };
}

export function resetLimitIndex() {
  return function(dispatch) {
    return dispatch(resetLimitIndexSuccess());
  }
}

export function updateSearchText({searchText}) {
  return function(dispatch) {
    return dispatch(updateSearchTextSuccess(searchText));
  }
}

export function updateMoreBtn({ isVisible }) {
  return function (dispatch) {
    return dispatch(updateMoreBtnSuccess(isVisible));
  };
}

export function resetSearchText() {
  return function(dispatch) {
    return dispatch(resetSearchTextSuccess());
  }
}

export function resetMoreBtn() {
  return function(dispatch) {
    return dispatch(resetMoreBtnSuccess())
  }
}

export function updateErroMessage({ errorMessage }) {
  return function (dispatch) {
    return dispatch(updateErroMessageSuccess(errorMessage));
  };
}
//response.json()
//dispatch(getProductsSuccess(result))
