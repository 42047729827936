import React, { Component } from "react";
import { Form, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions from "../../redux/actions/authActions";
import { Navigate } from "react-router-dom";
import "./LoginPage.css";

var bcrypt = require("bcryptjs");
var salt = bcrypt.genSaltSync(10);

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
    };
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const username = formData.get("username");
    const password = formData.get("password");

    //console.log("Username : " + username + " Password : " + password);

    if (this.isFormInvalid(username, password)) {
      //console.log("Alanları Doldurun!");
    } else {
      this.tryToLogin(username, password);
    }
  };

  async tryToLogin(username, password) {
    const hash = await this.props.actions.login({
      username: username,
    });

    if (hash === "Kullanıcı adı bulunamadı!") {
      this.setState({ error: "Kullanıcı adı bulunamadı! \nTekrar deneyiniz!" });
    } else if (bcrypt.compareSync(password, hash)) {
      await this.props.actions.setLogin();
    } else {
      this.setState({ error: "Şifre yanlış! \nTekrar deneyiniz!" });
    }
  }

  isFormInvalid(username, password) {
    if (username.trim() === "" && password.trim() === "") {
      /* document
        .getElementById("email")
        .setCustomValidity();//Here set the invalid to true */
      //this.showAlert("Kullanıcı Adı ve Şifre Giriniz!");
      this.setState({ error: "Lütfen Kullanıcı Adı ve Şifre Giriniz!" });
      return true;
    } else if (username.trim() === "") {
      /* document
        .getElementById("email")
        .setCustomValidity();//Here set the invalid to true */
      //this.showAlert("Kullanıcı Adı Giriniz!");
      this.setState({ error: "Lütfen Kullanıcı Adı Giriniz!" });
      return true;
    } else if (password.trim() === "") {
      /* document
        .getElementById("password")
        .setCustomValidity(); //Here set the invalid to true */
      //this.showAlert("Şifre Giriniz!");
      this.setState({ error: "Lütfen Şifre Giriniz!" });
      return true;
    }

    return false;
  }

  showAlert = (msg) => {
    window.alert(msg);
  };

  render() {
    const { isLoggedIn } = this.props;
    const { error } = this.state;

    if (isLoggedIn) {
      return <Navigate to="/appointments" replace={true} />;
    }
    return (
      <section className="containerlogin">
        <div className="login-container">
          <div className="form-container">
            <h1 className="opacity">
              Giriş
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                alt="Logo"
                style={{ width: "75px", height: "75px", marginLeft: "128px" }}
              />
            </h1>
            <div>
              <Form onSubmit={this.handleSubmit} name="loginForm">
                {/* <FormGroup floating> */}
                <Input
                  id="username"
                  name="username"
                  placeholder="Kullanıcı adı"
                  type="username"
                />
                {/* </FormGroup>{" "} */}
                {/* <FormGroup floating> */}
                <Input
                  id="password"
                  name="password"
                  placeholder="Şifre"
                  type="password"
                />
                {/* </FormGroup>{" "} */}
                <Button className="text">Giriş yap</Button>
              </Form>
            </div>
            {error && (
              <div
                style={{
                  marginLeft: "0px",
                  marginTop: "-20px",
                  color: "red",
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                {error}
              </div>
            )}{" "}
          </div>
        </div>
      </section>
    );

    //   <div>
    //     <h2>Giriş</h2>
    //     <Form onSubmit={this.handleSubmit} name="loginForm">
    //       <FormGroup floating>
    //         <Input id="username" name="username" placeholder="Kullanıcı Adı" type="username" />
    //       </FormGroup>{" "}
    //       <FormGroup floating>
    //         <Input
    //           id="password"
    //           name="password"
    //           placeholder="Şifre"
    //           type="password"
    //         />
    //       </FormGroup>{" "}
    //       <Button>Onayla</Button>
    //     </Form>
    //   </div>
    // );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.authReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      login: bindActionCreators(authActions.login, dispatch),
      setLogin: bindActionCreators(authActions.setLogin, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
