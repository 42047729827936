export const GET_APPOINTMENTS_SUCCESS = "GET_APPOINTMENTS_SUCCESS";
export const GET_APPOINTMENTS_FAILURE = "GET_APPOINTMENTS_FAILURE";
export const CLEAR_APPOINTMENTS_SUCCESS = "CLEAR_APPOINTMENTS_SUCCESS";
export const UPDATE_EXPIRED_APPOINTMENTS = "UPDATE_EXPIRED_APPOINTMENTS";
export const GET_APPOINTMENTS_CALENDAR_SUCCESS = "GET_APPOINTMENTS_CALENDAR_SUCCESS";
export const UPDATE_FIRST_DAY_OF_WEEK_SUCCESS =
  "UPDATE_FIRST_DAY_OF_WEEK_SUCCESS";
export const UPDATE_MORE_BTN_SUCCESS = "UPDATE_MORE_BTN_SUCCESS";
export const RESET_MORE_BTN = "RESET_MORE_BTN";
export const UPDATE_SELECTED_APPOINTMENT_SUCCESS =
  "UPDATE_SELECTED_APPOINTMENT_SUCCESS";

export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAILURE = "GET_CUSTOMERS_FAILURE";
export const CLEAR_CUSTOMERS_SUCCESS = "CLEAR_CUSTOMERS_SUCCESS";
export const UPDATE_SELECTED_CUSTOMER_SUCCESS = "UPDATE_SELECTED_CUSTOMER_SUCCESS";

export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";
export const CLEAR_EMPLOYEES_SUCCESS = "CLEAR_EMPLOYEES_SUCCESS";
export const UPDATE_SELECTED_EMPLOYEE_ID_SUCCESS =
  "UPDATE_SELECTED_EMPLOYEE_ID_SUCCESS";
export const UPDATE_SELECTED_EMPLOYEE_SUCCESS = "UPDATE_SELECTED_EMPLOYEE_SUCCESS"; 

export const GET_SERVICES_SUCCESS = "GET_SERVICES_SUCCESS";
export const GET_SERVICES_FAILURE = "GET_SERVICES_FAILURE";
export const CLEAR_SERVICES_SUCCESS = "CLEAR_SERVICES_SUCCESS";
export const UPDATE_SELECTED_SERVICE_SUCCESS = "UPDATE_SELECTED_SERVICE_SUCCESS"; 

export const GET_LOANS_SUCCESS = "GET_LOANS_SUCCESS";
export const GET_LOANS_FAILURE = "GET_LOANS_FAILURE";
export const CLEAR_LOANS_SUCCESS = "CLEAR_LOANS_SUCCESS";
export const UPDATE_SELECTED_LOAN_SUCCESS = "UPDATE_SELECTED_LOAN_SUCCESS";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"; 
export const INVALID_LOGIN = "INVALID_LOGIN";

export const UPDATE_LIMIT_COUNT = "UPDATE_LIMIT_COUNT";
export const UPDATE_LIMIT_INDEX = "UPDATE_LIMIT_INDEX";
export const UPDATE_SEARCH_TEXT = "UPDATE_SEARCH_TEXT";
export const RESET_SEARCH_TEXT = "RESET_SEARCH_TEXT";
export const RESET_LIMIT_INDEX = "RESET_LIMIT_INDEX";
export const RESET_LIMIT_COUNT = "RESET_LIMIT_COUNT";
export const UPDATE_ERROR_MESSAGE = "UPDATE_ERROR_MESSAGE";

//Custom Modal (POP UP)
export const UPDATE_MODAL_STATE = "UPDATE_MODAL_STATE";
export const UPDATE_UPDATE_MODAL_STATE = "UPDATE_UPDATE_MODAL_STATE";