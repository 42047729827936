const myObject = {
  isLoggedIn: false,
  limitCount: 25,
  limitIndex: 1,
  searchText: "",
  isExpiredAppointments: false,
  isMoreBtnVisible: true,
  selectedEmployeeId: 0,
  firstDayOfWeek: new Date(),
  appointments: [],
  appointmentsForCalendar: {},
  selectedAppointment: null,
  selectedEmployee: null,
  selectedCustomer: null,
  selectedService: null,
  selectedLoan: null,
  customers: [],
  employees: [],
  services: [],
  loans: new Map(),
  isModalOpen: false,
  isUpdateModalOpen: false,
  errorMessage: "",
};

export default myObject;