import * as actionsTypes from "./actionTypes";
import * as dbInfo from "./dbInfo";


export function getSuccess(services) {
  return { type: actionsTypes.GET_SERVICES_SUCCESS, payload: services};
}

export function getFailure(message) {
  return { type: actionsTypes.GET_SERVICES_FAILURE, payload: message };
}

export function updateSelectedServiceSuccess(selectedService) {
  return {
    type: actionsTypes.UPDATE_SELECTED_SERVICE_SUCCESS,
    payload: selectedService,
  };
}

export function getServices({searchText, limitCount, limitIndex, existingServices}) {
  return function (dispatch) {
    let url = "https://admin.beautyandrelaxyalova.com/barWebApp/getServicesLimited.php";
    let body = {
      searchText: searchText,
      limitIndex: limitIndex,
      limitCount: limitCount,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
          const newServices = existingServices.concat(result["services"]);
          return dispatch(getSuccess(newServices));
        } else {
          return result["message"];
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function getAllServices({
  searchText,
  existingServices,
}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/getServices.php";
    let body = {
      searchText,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
          return dispatch(getSuccess(result["services"]));
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function getServiceByName({serviceName}) {
  let url =
    "https://admin.beautyandrelaxyalova.com/barWebApp/getServiceByName.php";
  let body = {
    serviceName,
    dbUser: dbInfo.DB_USER,
    dbPassword: dbInfo.DB_PASS,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result["success"] === true) {
        const service = result["service"][0];
        return service;
      } else {
        return result["message"];
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
}

export function createServices({serviceName}) {
  return function (dispatch) {
    let url = "https://admin.beautyandrelaxyalova.com/barWebApp/createService.php";
    let body = {
      name: serviceName,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
          
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function updateSelectedService({ selectedService }) {
  return function (dispatch) {
    return dispatch(updateSelectedServiceSuccess(selectedService));
  };
}

export function updateService({ serviceID, name }) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/updateService.php";

    let body = {
      serviceID,
      name,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
          
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function deleteService({ serviceID }) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/deleteService.php";

    let body = {
      serviceID,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
          
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}
//response.json()
//dispatch(getProductsSuccess(result))
