import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import Login from "../auth/LoginPage"
import { Routes, Route } from "react-router-dom";
import Appointments from '../appointments/Appointments';
import NavBar from '../navi/NavBar';
import Calendar from '../appointments/Calendar';
import Employees from '../employees/Employees';
import Customers from '../customers/Customers';
import Services from '../services/Services';
import Loans from '../loans/Loans'

function App() {
  return (
    <div className="App">
      <NavBar></NavBar>
      <Routes>
        <Route path="/" element={<Login></Login>}></Route>
        <Route path="/Login" element={<Login />}></Route>
        <Route path="/Appointments" element={<Appointments />}></Route>
        <Route path="/Calendar" element={<Calendar />}></Route>
        <Route path="/Employees" element={<Employees />}></Route>
        <Route path="/Customers" element={<Customers />}></Route>
        <Route path="/Services" element={<Services />}></Route>
        <Route path="/Loans" element={<Loans />}></Route>
      </Routes>
    </div>
  );
}

export default App;


