import React, { Component } from 'react'
import "../root/App.css";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as filterActions from "../../redux/actions/filterActions";
import * as serviceActions from "../../redux/actions/serviceActions";

export class FilterService extends Component {
  handleChange = async (e) => {
    await this.props.actions.updateSearchText({ searchText: e.target.value });
    await this.props.actions.resetLimitIndex();
    await this.props.actions.resetMoreBtn();

    const searchVal = this.props.searchText;
    const limitIndex = this.props.limitIndex;
    const limitCount = this.props.limitCount;
    await this.props.actions.getServices({
      searchText: searchVal,
      limitCount,
      limitIndex,
      existingServices: [],
    });
  };

  render() {
    return (
      <div className='container'>
        <div style={{ padding: "15px", display: "flex", alignItems: "center" }}>
          <input
            className="searchField"
            onChange={this.handleChange}
            name="search"
            placeholder="Hizmet ara..."
            type="search"
            style={{ width: "100%"}}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    searchText: state.searchTextReducer,
    limitIndex: state.limitIndexReducer,
    limitCount: state.limitCountReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getServices: bindActionCreators(serviceActions.getServices, dispatch),
      updateSearchText: bindActionCreators(
        filterActions.updateSearchText,
        dispatch
      ),
      createServices: bindActionCreators(
        serviceActions.createServices,
        dispatch
      ),
      resetLimitIndex: bindActionCreators(
        filterActions.resetLimitIndex,
        dispatch
      ),
      resetMoreBtn: bindActionCreators(filterActions.resetMoreBtn, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FilterService);
