import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function selectedEmployeeIdReducer(
  state = initialState.selectedEmployeeId,
  action
) {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_EMPLOYEE_ID_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
