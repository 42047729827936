import React, { Component } from "react";
import LoanTable from "./LoanTable";
import FilterLoans from "./FilterLoans";
import withAuthRedirect from "../../hoc/withAuthRedirect";

export class Loans extends Component {
  render() {
    return (
      <div>
        <FilterLoans></FilterLoans>
        <LoanTable></LoanTable>
      </div>
    );
  }
}

export default withAuthRedirect(Loans);
