import React, { Component } from "react";
import { Button, Input, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import * as paymentActions from "../../redux/actions/paymentActions";
import * as loanActions from "../../redux/actions/loanActions";
import * as modalActions from "../../redux/actions/modalActions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { showSuccessAlert } from "../root/AlertService";

export class CreatePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      description: "",
      amount: "",
    };
  }

  handleCreatePayment = async () => {
    // Implement the logic for updating the appointment here
    //console.log("Update appointment:", appointment);
    // Optionally, you can update the state or perform any other actions here
    const { description, amount } = this.state;

    const loan = this.props.selectedLoan[0];

    /* console.log(
      "Selected \n" +
        loan.id+
        "\n"+
        description +
        "\n" +
        amount 
    ); */

    await this.props.actions.createPayment({
        loanID: loan.id,
        description,
        amount,
    });

    showSuccessAlert("Ödeme eklendi!");

    this.props.refreshLoans();
    this.closeModal();
  };

  closeModal = () => {
    // Close the modal and reset the selectedAppointment
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: false });
    this.props.actions.updateSelectedLoan({ selectedLoan: null });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  render() {
    const { isLoading, description, amount } =
      this.state;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <div>
        <ModalHeader toggle={this.closeModal}>Ödeme Oluştur</ModalHeader>
        <ModalBody>
          {/* Bitte Hier Weiter coden!!!!!!!!!!!!!!! */}
          <Input
            className="textField"
            name="description"
            placeholder="Açıklama"
            onChange={this.handleChange}
            value={description}
          />
          <Input
            className="textField"
            name="amount"
            placeholder="Tutar"
            type="number"
            onChange={this.handleChange}
            value={amount}
          />
        </ModalBody>
        <ModalFooter>
          {/* Update button */}
          <Button
            style={{ backgroundColor: "#f58634", border: "none" }}
            onClick={this.handleCreatePayment}
          >
            Oluştur
          </Button>
          {/* Cancel button */}
          <Button color="secondary" onClick={this.closeModal}>
            İptal
          </Button>
        </ModalFooter>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedLoan: state.selectedLoanReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      createPayment: bindActionCreators(
        paymentActions.createPayment,
        dispatch
      ),
      updateSelectedLoan: bindActionCreators(
        loanActions.updateSelectedLoan,
        dispatch
      ),
      updateUpdateModal: bindActionCreators(
        modalActions.updateUpdateModal,
        dispatch
      ),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePayment);
