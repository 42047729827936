import React, { Component } from 'react'
import FilterCustomer from "./FilterCustomer"
import CustomersTable from "./CustomersTable"
import withAuthRedirect from '../../hoc/withAuthRedirect'

export class Customers extends Component {
  render() {
    return (
      <div>
        <FilterCustomer></FilterCustomer>
        <CustomersTable></CustomersTable>
      </div>
    )
  }
}

export default withAuthRedirect(Customers);
