import { showFailureAlert, showSuccessAlert } from "../../components/root/AlertService";
import * as actionsTypes from "./actionTypes";
import * as dbInfo from "./dbInfo";

export function getSuccess(loans) {
  return { type: actionsTypes.GET_LOANS_SUCCESS, payload: loans};
}

export function getFailure(message) {
  return { type: actionsTypes.GET_LOANS_FAILURE, payload: message };
}

export function updateSelectedLoanSuccess(selectedLoan) {
  return {
    type: actionsTypes.UPDATE_SELECTED_LOAN_SUCCESS,
    payload: selectedLoan,
  };
}

export function getLoans({searchText, limitCount, limitIndex, existingLoans}) {
  return function (dispatch) {
    let url = "https://admin.beautyandrelaxyalova.com/barWebApp/getLoansLimited.php";
    let body = {
      limitCount,
      limitIndex,
      searchText,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
          const loansResult = result["loans"];
          let tableMap = new Map();

          for (let i = 0; i < loansResult.length; i++) {
            const loan = loansResult[i];

            if (!tableMap.has(loan.id)) {
              tableMap.set(loan.id, []);
            }

            tableMap.get(loan.id).push({
              id: loan.id,
              description: loan.description,
              customerId: loan.customerId,
              dateTime: loan.dateTime,
              amount: loan.amount,
              customerName: loan.customerName,
              customerSurname: loan.customerSurname,
              paymentId: loan.paymentId,
              paymentDescripion: loan.paymentDescripion,
              paymentDateTime: loan.paymentDateTime,
              paymentAmount: loan.paymentAmount,
            });
          }
          //console.log("LoanMap Result : "+JSON.stringify(tableMap))
          const combinedMap = new Map([...existingLoans, ...tableMap]);

          return dispatch(getSuccess(combinedMap));
        } else {
          return result["message"];
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function createLoan({ customerID, description, amount }) {
  return function (dispatch) {
    let url = "https://admin.beautyandrelaxyalova.com/barWebApp/createLoan.php";
    let body = {
      customerID,
      description,
      amount,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.text())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function updateSelectedLoan({ selectedLoan }) {
  return function (dispatch) {
    return dispatch(updateSelectedLoanSuccess(selectedLoan));
  };
}

export function deleteLoan({ loanID }) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/deleteLoan.php";

    let body = {
      loanID,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
          showSuccessAlert("Alacak Silindi!");
        } else {
          showFailureAlert("Hata! Alacak silinemedi. " + result["message"]);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}
//response.json()
//dispatch(getProductsSuccess(result))
