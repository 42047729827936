import * as actionTypes from "../../actions/actionTypes";
import initialState from "../initialState";

export default function selectedLoanReducer(
  state = initialState.selectedLoan,
  action
) {
  switch (action.type) {
    case actionTypes.UPDATE_SELECTED_LOAN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
