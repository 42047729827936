import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appointmentActions from "../../redux/actions/appointmentActions";
import * as filterActions from "../../redux/actions/filterActions";
import * as modalAction from "../../redux/actions/modalActions";
import UpdateAppointment from "./UpdateAppointment";
import ReactModal from "react-modal";

class AppointmentTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  refreshAppointments = async() => {
    await this.props.actions.resetLimitIndex();
    await this.props.actions.resetSearchText();
    await this.props.actions.resetMoreBtn();

    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchText = this.props.searchText;
    const getExpired = this.props.isExpiredAppointments;
    
    const resultMessage = await this.props.actions.getAppointments({
      limitIndex,
      limitCount,
      searchText,
      getExpired,
      existingAppointments: [],
    });

    if (
      Object.prototype.toString.call(resultMessage) === "[object String]" &&
        (resultMessage === "empty" ||
      resultMessage === "Randevu bulunamadı!")
    ) {
      this.props.actions.updateErrorMessage({errorMessage: "Randevu bulunamadı!"});
    }else {
      this.props.actions.updateErrorMessage({errorMessage: ""});
    }
    
    this.setState({
      isLoading: false,
    });
  }

  async componentDidMount() {
    await this.refreshAppointments();
  }

  getMoreAppointments = async (e) => {
    await this.props.actions.increaseLimitIndex();

    const limitCount = this.props.limitCount;
    const limitIndex = this.props.limitIndex;
    const searchText = this.props.searchText;
    const getExpired = this.props.isExpiredAppointments;

    const resultMessage = await this.props.actions.getAppointments({
      limitIndex,
      limitCount,
      searchText,
      getExpired,
      existingAppointments: this.props.appointments,
    });
    if(resultMessage==="empty"){
      await this.props.actions.updateMoreBtn({isVisible: false})
    }
  };

  toggleModal = async (selectedAppointment) => {
    await this.props.actions.updateSelectedAppointment({ selectedAppointment });
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: true });
  };

  closeModal = () => {
    // Close the modal and reset the selectedAppointment
    this.props.actions.updateUpdateModal({ isUpdateModalOpen: false });
    this.props.actions.updateSelectedAppointment({ selectedAppointment: null });
  };

  render() {
    const daysOfWeek = [
      "Pazar",
      "Pazartesi",
      "Salı",
      "Çarşamba",
      "Perşembe",
      "Cuma",
      "Cumartesi",
    ];

    const { isLoading } = this.state;

    const {
      isMoreBtnVisible,
      isUpdateModalOpen,
      selectedAppointment,
      errorMessage,
    } = this.props;

    if (isLoading) {
      return <div>Loading...</div>;
    }

    if(errorMessage) {
      return <div>{errorMessage}</div>;
    }

    return (
      <div className="container">
        <table className="responsive-table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Danışan</th>
              <th scope="col">Çalışan </th>
              <th scope="col">Tarih</th>
              <th scope="col">Saat</th>
              <th scope="col">Bitiş</th>
              <th scope="col">Gün</th>
              <th scope="col">Hizmetler</th>
            </tr>
          </thead>

          <tbody>
            {this.props.appointments.map((appointment, index) => (
              <React.Fragment key={index}>
                {/* Expandable row */}
                <tr onClick={() => this.toggleModal(appointment)}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    {appointment.customer?.name +
                      " " +
                      appointment.customer?.surname}
                  </td>
                  <td>
                    {appointment.employee?.name +
                      " " +
                      appointment.employee?.surname}
                  </td>
                  <td>{new Date(appointment.dateTime).toLocaleDateString()}</td>
                  <td>
                    {new Date(appointment.dateTime).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </td>
                  <td>
                    {new Date(appointment.dateTimeEnd).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </td>
                  <td>{daysOfWeek[new Date(appointment.dateTime).getDay()]}</td>
                  <td>{appointment.selectedServices}</td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {isMoreBtnVisible && (
          <Button onClick={this.getMoreAppointments}>More</Button>
        )}
        {/* Modal for update and delete buttons */}
        {selectedAppointment && (
          <ReactModal
            isOpen={isUpdateModalOpen}
            toggle={this.toggleModal}
            onRequestClose={this.closeModal}
            ariaHideApp={false}
          >
            <UpdateAppointment refreshAppointments={this.refreshAppointments}/>
          </ReactModal>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    isLoggedIn: state.authReducer,
    appointments: state.appointmentReducer,
    selectedAppointment: state.selectedAppointmentReducer,
    isExpiredAppointments: state.isExpiredReducer,
    limitIndex: state.limitIndexReducer,
    limitCount: state.limitCountReducer,
    searchText: state.searchTextReducer,
    isMoreBtnVisible: state.moreBtnReducer,
    isUpdateModalOpen: state.modalUpdateReducer,
    errorMessage: state.errorMessageReducer,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      getAppointments: bindActionCreators(
        appointmentActions.getAppointments,
        dispatch
      ),
      updateSelectedAppointment: bindActionCreators(
        appointmentActions.updateSelectedAppointment,
        dispatch
      ),
      increaseLimitIndex: bindActionCreators(
        filterActions.increaseLimitIndex,
        dispatch
      ),
      resetLimitIndex: bindActionCreators(
        filterActions.resetLimitIndex,
        dispatch
      ),
      updateUpdateModal: bindActionCreators(modalAction.updateUpdateModal, dispatch),
      updateMoreBtn: bindActionCreators(filterActions.updateMoreBtn, dispatch),
      resetSearchText: bindActionCreators(filterActions.resetSearchText, dispatch),
      resetMoreBtn: bindActionCreators(filterActions.resetMoreBtn, dispatch),
      updateErrorMessage: bindActionCreators(filterActions.updateErroMessage, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentTable);

