import * as actionsTypes from "./actionTypes";
import * as dbInfo from "./dbInfo";

export function getSuccess(customers) {
  return { type: actionsTypes.GET_CUSTOMERS_SUCCESS, payload: customers };
}

export function getFailure(message) {
  return { type: actionsTypes.GET_CUSTOMERS_FAILURE, payload: message };
}

export function updateSelectedCustomerSuccess(selectedCustomer) {
  return {
    type: actionsTypes.UPDATE_SELECTED_CUSTOMER_SUCCESS,
    payload: selectedCustomer,
  };
}

export function getCustomers({searchText, limitCount, limitIndex, existingCustomers}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/getCustomersLimited.php";
    let body = {
      searchText: searchText,
      limitIndex: limitIndex,
      limitCount: limitCount,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
          const newCustomers = existingCustomers.concat(result["customers"]);
          return dispatch(getSuccess(newCustomers));
        } else {
          return result["message"];
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function getCustomerById(customerID) {
  let url = "https://admin.beautyandrelaxyalova.com/barWebApp/getCustomerById.php";
  let body = {
    customerID: customerID,
    dbUser: dbInfo.DB_USER,
    dbPassword: dbInfo.DB_PASS,
  };
  return fetch(url, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((result) => {
      if (result["success"] === true) {
        const customer = result["customer"][0];
        return customer;
      } else {
        return result["message"];
      }
    })
    .catch((err) => {
      console.log(err.message);
    });
}

export function createCustomer({customerName, customerSurname, customerGender, customerTelephone, customerBirthday}) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/createCustomer.php";
    let body = {
      name: customerName,
      surname: customerSurname,
      gender: customerGender,
      telephone: customerTelephone,
      birthday: customerBirthday,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function updateSelectedCustomer({ selectedCustomer }) {
  return function (dispatch) {
    return dispatch(updateSelectedCustomerSuccess(selectedCustomer));
  };
}

export function updateCustomer({ customerID, name, surname, telephone }) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/updateCustomer.php";

    let body = {
      customerID,
      name,
      surname,
      telephone,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        //console.log(result);
        if (result["success"] === true) {
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}

export function deleteCustomer({ customerID }) {
  return function (dispatch) {
    let url =
      "https://admin.beautyandrelaxyalova.com/barWebApp/deleteCustomer.php";

    let body = {
      customerID,
      dbUser: dbInfo.DB_USER,
      dbPassword: dbInfo.DB_PASS,
    };
    //console.log(body);
    return fetch(url, {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((result) => {
        if (result["success"] === true) {
        } else {
          return dispatch(getFailure(result["message"]));
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
}
//response.json()
//dispatch(getProductsSuccess(result))
